import React, { useEffect, useState } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import rolesimage from "../images/left_icons/roles.png";
import LeftMenu from "../layout/leftMenu";
import { backendApi } from "../apiService";
import Header from "../layout/header";
import Footer from "../layout/footer";
import $ from "jquery";
import bank1Image from "../images/bank1.png";
import * as Icon from "react-feather";

const CompetitorList = () => {
  const [competitors, setCompetitors] = useState([]);

  const getCompetitors = () => {
    var req = {
      functionName: "getCompetitors",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };

    backendApi(req)
      .then((data) => {
        console.log("compData", data);
        if (data.success === "1") {
          setCompetitors(data.data);
        } else {
          console.log("Error getting competitors");
        }
      })
      .catch((error) => {
        console.log("Error connecting to server");
      });
  };

  useEffect(() => {
    getCompetitors();
  }, []);

  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Heading" />
        <div className="container-fluid mt-3">
          <div className="row row_gap30">
            {competitors.map((competitor) => (
              <div className="col-md-2">
                <div className="card border-0">
                  <div className="card-body card__body">
                    <div className="card__innerbody">
                      <div className="card__img">
                        <img
                          src={competitor.brand_logo}
                          alt="bank1Image"
                          className="img-fluid"
                        />
                      </div>
                      <div className="card__body_contt">
                        <p className="mb-0">{competitor.category}</p>
                        <div className="card_divider"></div>
                        <div className="card_body_icon">
                          <label className="mb-0 position-relative cursor-pointer">
                            <input type="file" />
                            <Icon.Upload />
                          </label>
                          <a href="" download>
                            <Icon.Download />
                          </a>
                        </div>
                        <div className="card_body_submit mt-3">
                          <button
                            type="button"
                            className="btn btn-danger w-100"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
};

export default CompetitorList;
