import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import $ from "jquery";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import GooglePostimage from "../images/left_icons/Google-Post.png";
import PostImg from "../images/bg_01.jpg";
import video from "../video/videoplayback.mp4";
import VideoPlayBtn from "../images/video-play-button.png";
import Pagination from "react-js-pagination";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as Icon from "react-feather";
import { useEffect, useState } from "react";
import { backendApi, apiCallingPost } from "../apiService";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Campaigns from "./components/campaigns";
import Adsets from "./components/adsets";
import Ads from "./components/ads";
import AdImages from "./components/ad_images";
import { Dropdown } from "react-bootstrap";
// import CampaignForm from "./components/campaign_form";sussss
import Modal from "react-modal";
import countries from "./pages/countryCodes.json";
// import imageUpload from "./components/imageUpload";
import axios from "axios";
import ImageUploading from "react-images-uploading";
import SocialMedia from "../images/left_icons/social-media.png";
import AWS from "aws-sdk";
import { getCampaign, getAdset, getAdImage } from "../apiService";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

var tempPostList = [];
export default function InstagramAdsReport() {
  var createAds = {
    width: "100px",
    height: "100px",
    display: "flex",
    overflow: "hidden",
    float: "left",
    alignItems: "center",
    padding: "0px 4px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    margin: "5px",
  };
  const [localPostList, setLocalPostList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [backupdata, setbackupdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [tabState, setTabState] = useState("1");
  // const [tabStateImages, setTabStateImages] = useState(false);
  // const [tabStateAdsets, setTabStateAdsets] = useState(false);
  // const [tabStateAds, setTabStateAds] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [adsets, setAdsets] = useState([]);
  const [type, setStype] = useState("daily");
  const [adsetsname, setAdsetsName] = useState([]);
  const [billing_event, setBilling] = useState([]);
  const [bid_amount, setBid] = useState([]);
  const [daily_budget, setDaily] = useState([]);
  const [lifetime, setLifetime] = useState([]);
  const [country, setCountry] = useState([]);
  const [campaign_id, setCampaignAdsets] = useState([]);
  const [adsets_id, setAdsAdsets] = useState([]);
  const [page_id, setPage_id] = useState([]);
  const [status, setStatusAdsets] = useState([]);
  const [optimization_goal, setOptimisation] = useState([]);
  const [images, setImages] = React.useState([]);
  const [ad_images, setAd_Images] = useState([]);
  const [ad_imagesHash, setAd_ImagesHash1] = useState("");
  const [campaignLoading, setCampaignLoading] = useState(new Date());
  const [pageId, setPages] = useState("");
  const [creative_name, setCreative_name] = useState([]);
  const [link, setLink] = useState("");
  const [message, setMessage] = useState("");
  const [adsName, setAdsName] = useState("");
  const [statusAds, setStatusAds] = useState("");
  const [tabName, setTabName] = useState("Campaign");
  const [campaignData, setcampaignData] = useState([]);
  const [file, setFile] = useState("");
  const [imageName, setImageName] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const [bytes, setBytes] = useState([]);
  const [sectionName, setSectionName] = useState("");

  const maxNumber = 69;
  const s3ImageUrl =
    "http://tagtree.s3-website.ap-south-1.amazonaws.com/production/images";
  var s3Region;
  var s3bucketAccessKey;
  var s3bucketSecretKey;
  var s3Bucket;
  var s3BucketMainFolder;
  var S3BucketImagesFolder = "images";
  var bucketParams;
  var s3;
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  let subtitle;
  var canvas;
  var ctx;
  var img = "";
  var src = "";
  var text_title = "";
  const objectives = [
    { name: "APP_INSTALLS", code: "APP_INSTALLS" },
    { name: "BRAND_AWARENESS", code: "BRAND_AWARENESS" },
    { name: "CONVERSIONS", code: "CONVERSIONS" },
    { name: "EVENT_RESPONSES", code: "EVENT_RESPONSES" },
    { name: "LEAD_GENERATION", code: "LEAD_GENERATION" },
    { name: "LINK_CLICKS", code: "LINK_CLICKS" },
    { name: "LOCAL_AWARENESS", code: "LOCAL_AWARENESS" },
    { name: "MESSAGES", code: "MESSAGES" },
    { name: "OFFER_CLAIMS", code: "OFFER_CLAIMS" },
    { name: "OUTCOME_APP_PROMOTION", code: "OUTCOME_APP_PROMOTION" },
    { name: "OUTCOME_AWARENESS", code: "OUTCOME_AWARENESS" },
    { name: "OUTCOME_LEADS", code: "OUTCOME_LEADS" },
    { name: "OUTCOME_SALES", code: "OUTCOME_SALES" },
    { name: "OUTCOME_TRAFFIC", code: "OUTCOME_TRAFFIC" },
    { name: "PAGE_LIKES", code: "PAGE_LIKES" },
    { name: "POST_ENGAGEMENT", code: "POST_ENGAGEMENT" },
    { name: "PRODUCT_CATALOG_SALES", code: "PRODUCT_CATALOG_SALES" },
    { name: "REACH", code: "REACH" },
    { name: "STORE_VISITS", code: "STORE_VISITS" },
    { name: "VIDEO_VIEWS", code: "VIDEO_VIEWS" },
  ];
  const statuses = [
    { name: "ACTIVE", code: "ACTIVE" },
    { name: "PAUSED", code: "PAUSED" },
    { name: "DELETED", code: "DELETED" },
    { name: "ARCHIVED", code: "ARCHIVED" },
  ];
  const categories = [
    { name: "NONE", code: "NONE" },
    { name: "EMPLOYMENT", code: "EMPLOYMENT" },
    { name: "HOUSING", code: "HOUSING" },
    { name: "CREDIT", code: "CREDIT" },
    { name: "ISSUES_ELECTIONS_POLITICS", code: "ISSUES_ELECTIONS_POLITICS" },
    { name: "ONLINE_GAMBLING_AND_GAMING", code: "ONLINE_GAMBLING_AND_GAMING" },
  ];
  const optimization_goals = [
    { name: "NONE", code: "NONE" },
    { name: "APP_INSTALLS", code: "APP_INSTALLS" },
    { name: "AD_RECALL_LIFT", code: "AD_RECALL_LIFT" },
    { name: "ENGAGED_USERS", code: "ENGAGED_USERS" },
    { name: "EVENT_RESPONSES", code: "EVENT_RESPONSES" },
    { name: "IMPRESSIONS", code: "IMPRESSIONS" },
    { name: "LEAD_GENERATION", code: "LEAD_GENERATION" },
    { name: "QUALITY_LEAD", code: "QUALITY_LEAD" },
    { name: "LINK_CLICKS", code: "LINK_CLICKS" },
    { name: "OFFSITE_CONVERSIONS", code: "OFFSITE_CONVERSIONS" },
    { name: "PAGE_LIKES", code: "PAGE_LIKES" },
    { name: "POST_ENGAGEMENT", code: "POST_ENGAGEMENT" },
    { name: "QUALITY_CALL", code: "QUALITY_CALL" },
    { name: "REACH", code: "REACH" },
    { name: "LANDING_PAGE_VIEWS", code: "LANDING_PAGE_VIEWS" },
    { name: "VISIT_INSTAGRAM_PROFILE", code: "VISIT_INSTAGRAM_PROFILE" },
    { name: "VALUE", code: "VALUE" },
    { name: "THRUPLAY", code: "THRUPLAY" },
    { name: "DERIVED_EVENTS", code: "DERIVED_EVENTS" },
    {
      name: "APP_INSTALLS_AND_OFFSITE_CONVERSIONS",
      code: "APP_INSTALLS_AND_OFFSITE_CONVERSIONS",
    },
    { name: "CONVERSATIONS", code: "CONVERSATIONS" },
    { name: "IN_APP_VALUE", code: "IN_APP_VALUE" },
    {
      name: "MESSAGING_PURCHASE_CONVERSION",
      code: "MESSAGING_PURCHASE_CONVERSION",
    },
    {
      name: "MESSAGING_APPOINTMENT_CONVERSION",
      code: "MESSAGING_APPOINTMENT_CONVERSION",
    },
  ];
  const billing_events = [
    { name: "APP_INSTALLS", code: "APP_INSTALLS" },
    { name: "CLICKS", code: "CLICKS" },
    { name: "IMPRESSIONS", code: "IMPRESSIONS" },
    { name: "LINK_CLICKS", code: "LINK_CLICKS" },
    { name: "NONE", code: "NONE" },
    { name: "OFFER_CLAIMS", code: "OFFER_CLAIMS" },
    { name: "PAGE_LIKES", code: "PAGE_LIKES" },
    { name: "POST_ENGAGEMENT", code: "POST_ENGAGEMENT" },
    { name: "THRUPLAY", code: "THRUPLAY" },
    { name: "LISTING_INTERACTION", code: "LISTING_INTERACTION" },
  ];
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    console.log("..sdec", location);
    $("#editMatchAddress").attr("hidden", true);
    $("#watchVideo").attr("hidden", true);
    if (typeof location.state !== "undefined" && location.state != null) {
      const { sectionName } = location.state;
      setSectionName(sectionName);
    }

    pagesList();
    getS3Credentials();
    const credentialReq = {
      functionName: "get_campaigns",
      site_id: "58",
    };
    backendApi(credentialReq)
      .then((data) => {
        console.log("campaignlist.....", data.body);
        setcampaignData(JSON.parse(data.body).data);
        let tempCampaigns = [];
        tempCampaigns.push({ name: "NONE", code: "NONE" });
        JSON.parse(data.body).data.forEach((element) => {
          let obj = { code: element.id, name: element.name };
          tempCampaigns.push(obj);
        });

        setCampaigns(tempCampaigns);
      })
      .catch(() => { });

    const adsetsData = {
      functionName: "get_adsets",
      site_id: "58",
    };
    backendApi(adsetsData)
      .then((data) => {
        console.log("..............adsets", JSON.parse(data.body).data);
        setAdsets(JSON.parse(data.body).data);
      })
      .catch(() => { });

    const imagesData = {
      functionName: "get_ad_images",
      site_id: "58",
    };
    backendApi(imagesData)
      .then((data) => {
        console.log("..............adImages", JSON.parse(data.body).data);
        setAd_Images(JSON.parse(data.body).data);
      })
      .catch(() => { });
  }, []);
  function pagesList() {
    let url =
      "https://lfck8764cg.execute-api.ap-south-1.amazonaws.com/default/list-pages";
    axios
      .post(url, {
        userid: "3332668366947745",
        access_token:
          "EAALIuyKeks0BABwbwznj4dQXJYlwIhBHGoFTCtZAZBvaZCMUyCWe1ZBGZCKFSZB2ZCvl55toCnNZBlK6CkSUKxDRWSIgdqzkDM1TquC9jnVGjJZCCz8ViEz9hKvUNQt1KEtHQugeNq16yrN51pYh4omZBBFb1201mVEwVODLrAMbaYnQZDZD",
      })
      .then((res) => {
        setPages(res?.data?.data);
      })
      .catch((err) => { });
  }

  function openModal() {
    setIsOpen(true);
    if (tabState === "2") {
      setAdsetsName("");
      setCampaignAdsets("");
      setOptimisation("");
      setBilling("");
      setBid("");
      setDaily("");
      setLifetime("");
      setCountry("");
      setStatusAdsets("");
    } else if (tabState === "3") {
      setImages([]);
      setBytes([]);
    }
  }
  function campaignAdsetsfn(e) {
    setCampaignAdsets(e);
    var campaignList = campaignData.filter((obj) => {
      return obj.id === e;
    });
    setOptimisation(campaignList[0].objective);
  }
  function ad_ImagesHash(data, id, e) {
    $(".Tag_imgActive").removeClass("imgActiveEnabled");
    $("#Tag_imgActive" + id).addClass("imgActiveEnabled");
    setAd_ImagesHash1(data.hash);
  }
  function tabAdsets() {
    setTabState("2");
    setTabName("Adsets");
  }
  function imageSubmit() {
    closeModal();
    let postData = {
      functionName: "upload_ad_images",
      site_id: "58",
      bytes: bytes,
    };
    apiCallingPost(postData)
      .then((res) => {
        setCampaignLoading(new Date());
        alert("Added Successfully");
        console.log(res.data);
      })
      .catch((err) => {
        setCampaignLoading(new Date());
        console.log(err);
      });
    setCampaignLoading(new Date());
    setBytes([]);
  }
  function tabCampaign() {
    setTabState("1");
    setTabName("Campaign");
  }
  function tabImages() {
    setTabState("3");
    setTabName("Images");
  }

  function tabAds() {
    setTabState("4");
    setTabName("Ads");
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const onChange = (imageList, addUpdateIndex) => {
    imageList.forEach((element) => {
      setBytes(element.data_url.split("base64,")[1]);
    });
    setImages(imageList);
  };

  const formSubmit = (e) => {
    var flag = true;
    if (document.getElementById("inputName").value === "") {
      alert("Please Enter Name");
      $("#inputName").focus();
      flag = false;
    } else if (document.getElementById("Objective").value === "") {
      alert("Please Enter Objective");
      $("#inputName").focus();
      flag = false;
    } else if (document.getElementById("Status").value === "") {
      alert("Please Enter Status");
      $("#Status").focus();
      flag = false;
    } else if (document.getElementById("Categories").value === "") {
      alert("Please Enter Categories");
      $("#Categories").focus();
      flag = false;
    }
    if (flag === true) {
      let obj = {
        functionName: "create_campaign",
        site_id: "58",
        campaign_name: document.getElementById("inputName").value,
        objective: document.getElementById("Objective").value,
        status: document.getElementById("Status").value,
        special_ad_categories: document.getElementById("Categories").value,
      };
      apiCallingPost(obj)
        .then((res) => {
          setCampaignLoading(new Date());
          alert("Added Successfully");
          console.log(res.data);
        })
        .catch((error) => {
          setCampaignLoading(new Date());
          console.log(error);
        });
      closeModal();
      setCampaignLoading(new Date());
    }
  };
  const formSubmitAdsets = (e) => {
    var name = adsetsname;
    let obj = null;
    let optimisationArray = {
      name: $("#optimisation option:selected").text(),
      code: document.getElementById("optimisation").value,
    };
    if (type == "daily") {
      obj = {
        functionName: "create_adsets",
        site_id: "58",
        type,
        name,
        optimization_goal,
        billing_event,
        bid_amount,
        daily_budget,
        campaign_id,
        targeting: {
          geo_locations: { countries: [country] },
        },
        status,
      };
    } else {
      obj = {
        functionName: "create_adsets",
        site_id: "58",
        name,
        optimisationArray,
        billing_event,
        bid_amount,
        lifetime,
        campaign_id,
        targeting: {
          geo_locations: { countries: [country] },
        },
        status,
      };
    }
    apiCallingPost(obj)
      .then((res) => {
        console.log(res.data);
        alert("Added Successfully");
        setCampaignLoading(new Date());
      })
      .catch((error) => {
        console.log(error);
        setCampaignLoading(new Date());
      });
    closeModal();
    setCampaignLoading(new Date());
  };

  const AdsSave = (e) => {
    var name = adsName;
    let obj = {
      functionName: "create_ads",
      site_id: "58",
      name,
      adsets_id: adsets_id,
      creative_name,
      page_id: page_id,
      image_hash: ad_imagesHash,
      link,
      message,
      status: statusAds,
    };
    apiCallingPost(obj)
      .then((res) => {
        setCampaignLoading(new Date());
        alert("Added Successfully");
      })
      .catch(() => {
        setCampaignLoading(new Date());
      });
  };

  const getS3Credentials = () => {
    const credentialReq = {
      functionName: "s3Credentials",
    };
    backendApi(credentialReq).then((data) => {
      // setS3Credentials(data.data[0]);
      s3Region = "us-east-1";
      s3bucketAccessKey = process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY;
      s3bucketSecretKey = process.env.REACT_APP_TAGTREE_S3_SECRET_KEY;
      s3Bucket = "tagtree";
      s3BucketMainFolder = "production";
      S3BucketImagesFolder = "images";
      bucketParams = {
        Bucket: s3Bucket,
      };
      AWS.config.update({
        accessKeyId: s3bucketAccessKey,
        secretAccessKey: s3bucketSecretKey,
        region: s3Region,
      });
      s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: bucketParams,
      });
      // console.log("s3", s3);
    });
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading={sectionName} headerImage={SocialMedia} />
        {console.log(
          "🚀 ~ file: instagramAdsReport.jsx:538 ~ InstagramAdsReport ~ sectionName:",
          sectionName
        )}
        <div className="container-fluid main_adswrapper">
          {/* <div className="subhead">Post(s) History</div> */}

          {/* ================================ */}
          <Tabs className="tabs-main">
            <div className="tabs_headsec">
              <TabList>
                <Tab onClick={tabCampaign}>Campaign</Tab>
                <Tab onClick={tabAdsets}>Adsets</Tab>
                <Tab onClick={tabImages}>Images</Tab>
                <Tab onClick={tabAds}>Ads</Tab>

                {/* <Tab>Reports</Tab> */}
                {/* <Tab onClick={openModal}>Create</Tab> */}
              </TabList>

              <div>
                <button
                  type="button"
                  class="btn btn-primary mr-3"
                  onClick={openModal}
                >
                  Create {tabName}
                </button>
                {/* <select class="form-control">
                  <option value="">select</option>
                </select> */}
              </div>
            </div>
            <TabPanel>
              <Campaigns key={campaignLoading} />
            </TabPanel>
            <TabPanel>
              <Adsets key={campaignLoading} />
            </TabPanel>
            <TabPanel>
              <AdImages key={campaignLoading} />
            </TabPanel>
            <TabPanel>
              <Ads key={campaignLoading} />
            </TabPanel>
            {/* <TabPanel>
              <Ads/>
            </TabPanel> */}
          </Tabs>
          <div>
            {/* <button >Open Modal</button> */}
            {tabState === "1" ? (
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div className="tag_campaignPopup">
                  <h2>Create Campaign</h2>

                  <form>
                    <div
                      className="form-group tag_formLabel"
                      style={{ marginBottom: "5px" }}
                    >
                      <label>
                        Name<sup className="text-danger">*</sup>
                      </label>
                      <input
                        id={"inputName"}
                        type="text"
                        className="form-control"
                        style={{ marginBottom: "5px" }}
                      />
                    </div>
                    <div
                      className="form-group tag_formLabel"
                      style={{ marginBottom: "5px" }}
                    >
                      <label>
                        Objective <sup className="text-danger">*</sup>
                      </label>
                      <select
                        id={"Objective"}
                        className="form-control"
                        style={{ marginBottom: "5px" }}
                      >
                        <option value="">Please Select Objective</option>
                        {objectives.map((object, index) => (
                          <option value={object.name}>{object.name}</option>
                        ))}
                      </select>
                    </div>
                    <div
                      className="form-group tag_formLabel"
                      style={{ marginBottom: "5px" }}
                    >
                      <label>
                        Status <sup className="text-danger">*</sup>
                      </label>
                      <select
                        id={"Status"}
                        className="form-control"
                        style={{ marginBottom: "5px" }}
                      >
                        <option value="">Please Select Status</option>
                        {statuses.map((status, index) => (
                          <option value={status.name}>{status.name}</option>
                        ))}
                      </select>
                    </div>
                    <div
                      className="form-group tag_formLabel"
                      style={{ marginBottom: "5px" }}
                    >
                      <label>
                        Special Ad Categories
                        <sup className="text-danger">*</sup>
                      </label>
                      <select
                        id={"Categories"}
                        className="form-control"
                        style={{ marginBottom: "5px" }}
                      >
                        <option value="">
                          Please Select Special Ad Categories
                        </option>
                        {categories.map((category, index) => (
                          <option value={category.name}>{category.name}</option>
                        ))}
                      </select>
                    </div>
                  </form>

                  <div class="button-wrapper">
                    <button
                      type="button"
                      class="btn-submit"
                      onClick={formSubmit}
                      style={{ cursor: "pointer" }}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      class="btn-cancel"
                      onClick={closeModal}
                      style={{ cursor: "pointer" }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Modal>
            ) : tabState === "2" ? (
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div className="tag_campaignPopup">
                  <h2>Create Adset</h2>

                  <form>
                    <div
                      className="form-group tag_formLabel"
                      style={{ marginBottom: "5px" }}
                    >
                      <label>Name</label>
                      <input
                        id="adsetsName"
                        onChange={(e) => setAdsetsName(e.target.value)}
                        value={adsetsname}
                        type="text"
                        className="form-control"
                        style={{ marginBottom: "5px" }}
                      />
                    </div>
                    <div
                      className="form-group tag_formLabel"
                      style={{ marginBottom: "5px" }}
                    >
                      <label>Campaign</label>
                      <select
                        id="campaignAdsets"
                        onChange={(e) => campaignAdsetsfn(e.target.value)}
                        value={campaign_id}
                        className="form-control"
                        style={{ marginBottom: "5px" }}
                      >
                        <option value="">Please Select Campaign</option>
                        {campaigns.map((data, index) => (
                          <option value={data.code}>{data.name}</option>
                        ))}
                      </select>
                    </div>
                    <div
                      className="form-group tag_formLabel"
                      style={{ marginBottom: "5px" }}
                    >
                      <label>Optimisation Goal</label>
                      <input
                        id="optimisation"
                        // onChange={(e) => setAdsetsName(e.target.value)}
                        value={optimization_goal}
                        readOnly
                        type="text"
                        className="form-control"
                        style={{ marginBottom: "5px" }}
                      />
                      {/* <select
                        id="optimisation"
                        onChange={(e) => setOptimisation(e.target.value)}
                        value={optimization_goal}
                        className="form-control" style={{marginBottom:"5px"}}
                      >
                        <option value="">Please Select Optimisation Goal</option>
                        {optimization_goals.map((object, index) => (
                          <option value={object.code}>{object.name}</option>
                        ))}
                      </select> */}
                    </div>
                    <div
                      className="form-group tag_formLabel"
                      style={{ marginBottom: "5px" }}
                    >
                      <label>Billing Event</label>
                      <select
                        id="billing"
                        onChange={(e) => setBilling(e.target.value)}
                        value={billing_event}
                        className="form-control"
                        style={{ marginBottom: "5px" }}
                      >
                        <option value="">Please Select Billing Event</option>
                        {billing_events.map((status, index) => (
                          <option value={status.name}>{status.name}</option>
                        ))}
                      </select>
                    </div>
                    <div
                      className="form-group tag_formLabel"
                      style={{ marginBottom: "5px" }}
                    >
                      <label>Bid Amount</label>
                      <input
                        id="bid"
                        onChange={(e) => setBid(e.target.value)}
                        value={bid_amount}
                        type="text"
                        className="form-control"
                        style={{ marginBottom: "5px" }}
                      />
                    </div>
                    {type == "daily" ? (
                      <div
                        className="form-group tag_formLabel"
                        style={{ marginBottom: "5px" }}
                      >
                        <label>Daily Budget</label>
                        <input
                          id="daily"
                          onChange={(e) => setDaily(e.target.value)}
                          value={daily_budget}
                          type="text"
                          className="form-control"
                          style={{ marginBottom: "5px" }}
                        />
                      </div>
                    ) : (
                      <div
                        className="form-group tag_formLabel"
                        style={{ marginBottom: "5px" }}
                      >
                        <label>Lifetime Budget</label>
                        <input
                          id="lifetime"
                          onChange={(e) => setLifetime(e.target.value)}
                          value={lifetime}
                          type="text"
                          className="form-control"
                          style={{ marginBottom: "5px" }}
                        />
                      </div>
                    )}
                    <div
                      className="form-group tag_formLabel"
                      style={{ marginBottom: "5px" }}
                    >
                      <label>Country</label>
                      <select
                        id="country"
                        onChange={(e) => setCountry(e.target.value)}
                        value={country}
                        className="form-control"
                        style={{ marginBottom: "5px" }}
                      >
                        <option value="">Please Select Country</option>
                        {countries.data.map((data, index) => (
                          <option value={data.code}>{data.name}</option>
                        ))}
                      </select>
                    </div>

                    <div
                      className="form-group tag_formLabel"
                      style={{ marginBottom: "5px" }}
                    >
                      <label>Status</label>
                      <select
                        id="statusAdsets"
                        onChange={(e) => setStatusAdsets(e.target.value)}
                        value={status}
                        className="form-control"
                        style={{ marginBottom: "5px" }}
                      >
                        <option value="">Please Select Status</option>
                        {statuses.map((status, index) => (
                          <option value={status.name}>{status.name}</option>
                        ))}
                      </select>
                    </div>
                  </form>
                  <div class="button-wrapper">
                    <button
                      type="button"
                      class="btn-submit"
                      onClick={formSubmitAdsets}
                      style={{ cursor: "pointer" }}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      class="btn-cancel"
                      onClick={closeModal}
                      style={{ cursor: "pointer" }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Modal>
            ) : tabState === "3" ? (
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div className="tag_campaignPopup">
                  <h2>Create Images</h2>

                  <form>
                    {/* <div className="form-group position-relative">
                        <input id="adsetsName" type="file" className="form-control forminput_text" placeholder="dhaaamu" />
                      </div> */}

                    <div className="App">
                      <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="upload__image-wrapper">
                            {imageList.length < "1" ? (
                              <span
                                className="upload__imagebtn"
                                style={
                                  isDragging ? { color: "red" } : undefined
                                }
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                Choose Image
                              </span>
                            ) : (
                              ""
                            )}
                            &nbsp;
                            {/* <span
                              className="upload__imagebtn"
                              onClick={onImageRemoveAll}
                            >
                              Remove all images
                            </span> */}
                            <span className="tag_imgCenter">
                              {imageList.map((image, index) => (
                                <div
                                  key={index}
                                  className="image-item tag_imgItems"
                                >
                                  <img
                                    src={image["data_url"]}
                                    alt=""
                                    width="100"
                                  />
                                  <div className="image-item__btn-wrapper">
                                    <span
                                      className="btn-submit"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => onImageUpdate(index)}
                                    >
                                      Update
                                    </span>
                                    <span
                                      className="btn-cancel"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => onImageRemove(index)}
                                    >
                                      Remove
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </span>
                          </div>
                        )}
                      </ImageUploading>
                      {/* <div className="tag_upload-btn-wrapper">
                        <button className="btn">Choose Image</button>
                        <input
                          type="file"
                          name="instaAdimage"
                          id="instaAdimage"
                          className="controls__input"
                          onChange={(e) =>handleImage(e)}
                        />
                        <img src={imagePreview} />
                      </div> */}
                    </div>
                  </form>

                  <div class="button-wrapper">
                    {images.length > "0" ? (
                      <button
                        type="button"
                        class="btn-submit"
                        id="save"
                        onClick={imageSubmit}
                        style={{ cursor: "pointer" }}
                      >
                        Submit
                      </button>
                    ) : (
                      ""
                    )}
                    <button
                      type="button"
                      id="cancel"
                      class="btn-cancel"
                      onClick={closeModal}
                      style={{ cursor: "pointer" }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Modal>
            ) : tabState === "4" ? (
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div className="tag_campaignPopup">
                  <h2>Create Ads</h2>



                  <Accordion>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Step 1
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          <form>
                            <div
                              className="form-group tag_formLabel"
                              style={{ marginBottom: "5px" }}
                            >
                              <label>Name</label>
                              <input
                                id="adsName"
                                onChange={(e) => setAdsName(e.target.value)}
                                value={adsName}
                                type="text"
                                className="form-control"
                                style={{ marginBottom: "5px" }}
                              />
                            </div>
                            <div
                              className="form-group tag_formLabel"
                              style={{ marginBottom: "5px" }}
                            >
                              <label>Status</label>
                              <select
                                id="campaignAdsets"
                                onChange={(e) => setStatusAds(e.target.value)}
                                value={statusAds}
                                className="form-control"
                                style={{ marginBottom: "5px" }}
                              >
                                <option value="">Please Select Status</option>
                                {statuses.map((data, index) => (
                                  <option value={data.name}>{data.name}</option>
                                ))}
                              </select>
                            </div>
                            <div
                              className="form-group tag_formLabel"
                              style={{ marginBottom: "5px" }}
                            >
                              <label>Adsets</label>
                              <select
                                id="campaignAdsets"
                                onChange={(e) => setAdsAdsets(e.target.value)}
                                value={adsets_id}
                                className="form-control"
                                style={{ marginBottom: "5px" }}
                              >
                                <option value="">Please Select Adsets</option>
                                {adsets.map((data, index) => (
                                  <option value={data.id}>{data.name}</option>
                                ))}
                              </select>
                            </div>
                            <div
                              className="form-group tag_formLabel"
                              style={{ marginBottom: "5px" }}
                            >
                              <label>Creative_name</label>
                              <input
                                id="adsCreativeName"
                                onChange={(e) => setCreative_name(e.target.value)}
                                value={creative_name}
                                type="text"
                                className="form-control"
                                style={{ marginBottom: "5px" }}
                              />
                            </div>
                            <div
                              className="form-group tag_formLabel"
                              style={{ marginBottom: "5px" }}
                            >
                              <label>Link</label>
                              <input
                                id="adsLinkName"
                                onChange={(e) => setLink(e.target.value)}
                                value={link}
                                type="text"
                                className="form-control"
                                style={{ marginBottom: "5px" }}
                              />
                            </div>
                            <div
                              className="form-group tag_formLabel"
                              style={{ marginBottom: "5px" }}
                            >
                              <label>Message</label>
                              <input
                                id="adsMessageName"
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                                type="text"
                                className="form-control"
                                style={{ marginBottom: "5px" }}
                              />
                            </div>
                            <div className="form-group tag_formLabel">
                              <label>Pages</label>
                              <select
                                id="campaignAdsets"
                                onChange={(e) => setPage_id(e.target.value)}
                                value={page_id}
                                className="form-control"
                              >
                                <option value="">Please Select Pages</option>
                                {pageId.map((data, index) => (
                                  <option value={data.id}>{data.name}</option>
                                ))}
                              </select>
                            </div>
                            
                          </form>
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Step 2
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                      <p>
                          <form>
                            <div
                              className="form-group tag_formLabel"
                              style={{ marginBottom: "5px" }}
                            >
                      <Tabs>
                              <TabList>
                                {/* <Tab>Upload</Tab> */}
                                <Tab>Choose</Tab>
                              </TabList>
                              {/* <TabPanel>
                       <>
                       <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange1}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload1,
                          onImageRemoveAll1,
                          onImageUpdate1,
                          onImageRemove1,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="upload__image-wrapper">
                            <span
                              className="upload__imagebtn"
                              style={isDragging ? { color: "red" } : undefined}
                              onClick={onImageUpload1}
                              {...dragProps}
                            >
                              Click or Drop here
                            </span>
                            &nbsp;
                            <span
                              className="upload__imagebtn"
                              onClick={onImageRemoveAll1}
                            >
                              Remove all images
                            </span>
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width="100"
                                />
                                <div className="image-item__btn-wrapper">
                                  <span onClick={() => onImageUpdate1(index)}>
                                    Update
                                  </span>
                                  <span onClick={() => onImageRemove1(index)}>
                                    Remove
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </ImageUploading>
                       </>
                      </TabPanel> */}
                              <TabPanel>
                                <>
                                  <div className="form-group imageGroup">
                                    {ad_images.map((data, index) => (
                                      <div
                                        style={createAds}
                                        onClick={(e) => ad_ImagesHash(data, index, e)}
                                        className=" Tag_imgActive"
                                        id={"Tag_imgActive" + index}
                                      >
                                        <img src={data.url} className="img-fluid"></img>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              </TabPanel>
                            </Tabs>
                            </div>
                            </form>
                            </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Step 3
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Please confirm and then press the save button to set this as ads
                        </p>
                        <div class="button-wrapper">
                    <button
                      type="button"
                      class="btn-submit"
                      onClick={AdsSave}
                      style={{ cursor: "pointer" }}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      class="btn-cancel"
                      onClick={closeModal}
                      style={{ cursor: "pointer" }}
                    >
                      Cancel
                    </button>
                  </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>

                  
                </div>
              </Modal>
            ) : (
              ""
            )}
          </div>
          {/* ================================ */}
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden></div>
    </div>
  );
}
