import React, { Component } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import QandA from "../images/left_icons/qa.png";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import Modal from "react-modal";
import $ from "jquery";

class QuestionAnswerPdf extends Component {

    render() {
        return (
            <div className="main_wrapper">

                <LeftMenu />
                <div className="userListWrapper">
                    <Header heading="Question Answer Pdf" headerImage={QandA} />
                    <div className="container px-5">
                        <div className="row pt-3 ">

                            <div className="col-md-3 mb-2">
                                <p class="invoice_para"><span class="invoice_para_bold">Label name : </span>CIMPL-194-22/23</p>
                            </div>
                            <div className="col-md-3 mb-2">
                                <p class="invoice_para"><span class="invoice_para_bold">Label name : </span>CIMPL-194-22/23</p>
                            </div> <div className="col-md-3 mb-2">
                                <p class="invoice_para"><span class="invoice_para_bold">Label name : </span>CIMPL-194-22/23</p>
                            </div> <div className="col-md-3 mb-2">
                                <p class="invoice_para"><span class="invoice_para_bold">Label name : </span>CIMPL-194-22/23</p>
                            </div> <div className="col-md-3 mb-2">
                                <p class="invoice_para"><span class="invoice_para_bold">Label name : </span>CIMPL-194-22/23</p>
                            </div> <div className="col-md-3 mb-2">
                                <p class="invoice_para"><span class="invoice_para_bold">Label name : </span>CIMPL-194-22/23</p>
                            </div> <div className="col-md-3 mb-2">
                                <p class="invoice_para"><span class="invoice_para_bold">Label name : </span>CIMPL-194-22/23</p>
                            </div> <div className="col-md-3 mb-2">
                                <p class="invoice_para"><span class="invoice_para_bold">Label name : </span>CIMPL-194-22/23</p>
                            </div> <div className="col-md-3 mb-2">
                                <p class="invoice_para"><span class="invoice_para_bold">Label name : </span>CIMPL-194-22/23</p>
                            </div> <div className="col-md-3 mb-2">
                                <p class="invoice_para"><span class="invoice_para_bold">Label name : </span>CIMPL-194-22/23</p>
                            </div>
                        </div>
                        <div class="row mt-4">

                            <ul id="accordion" class="col-sm-6 col-md-12 qa-accordion">

                                <li>
                                    <div data-toggle="collapse">
                                        <span className="mr-2 qntext">Qn.</span>How often do you upgrade the system ?
                                    </div>
                                    <div class="collapse show mt-2">
                                        <div class="card-body">
                                            <ul>
                                                <li> <span className="mr-2 anstext">Ans1 :</span> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.  </li>
                                                <div className="my-2">
                                                    <div
                                                        className="requirement_date ml-auto"
                                                        style={{
                                                            background: "#c3e9cd",
                                                            padding: "1px 10px",
                                                            borderRadius: "5px",
                                                            width: "fit-content",
                                                        }}
                                                    >
                                                       <b>Approved By</b>
                                                        <span className="text-dark">
                                                            fdegfrg
                                                        </span>
                                                    </div>
                                                </div>
                                                <li> <span className="mr-2 anstext">Ans2 :</span>3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.  </li>
                                                <div className="my-2">
                                                    <div
                                                        className="requirement_date ml-auto"
                                                        style={{
                                                            background: "#c3e9cd",
                                                            padding: "1px 10px",
                                                            borderRadius: "5px",
                                                            width: "fit-content",
                                                        }}
                                                    >
                                                        <b>Approved By</b>
                                                        <span className="text-dark">
                                                            fdegfrg
                                                        </span>
                                                    </div>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </li>

                                <li className="mt-2">
                                    <div class="collapsed" data-toggle="collapse">
                                        Where do I go incase I have a complaint?
                                    </div>
                                    <div class="collapse show mt-2" >
                                        <div class="card-body">
                                            <ul>
                                                <li> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.  </li>
                                                <div className="my-2">
                                                    <div
                                                        className="requirement_date ml-auto"
                                                        style={{
                                                            background: "#c3e9cd",
                                                            padding: "1px 10px",
                                                            borderRadius: "5px",
                                                            width: "fit-content",
                                                        }}
                                                    >
                                                       <b>Approved By</b>
                                                        <span className="text-dark">
                                                            fdegfrg
                                                        </span>
                                                    </div>
                                                </div>
                                                <li> 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.  </li>
                                                <div className="my-2">
                                                    <div
                                                        className="requirement_date ml-auto"
                                                        style={{
                                                            background: "#c3e9cd",
                                                            padding: "1px 10px",
                                                            borderRadius: "5px",
                                                            width: "fit-content",
                                                        }}
                                                    >
                                                       <b>Approved By</b>
                                                        <span className="text-dark">
                                                            fdegfrg
                                                        </span>
                                                    </div>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </li>




                            </ul>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default QuestionAnswerPdf;
