import React, { Component } from "react";
import "../css/bootstrap.css";
import { Link } from "react-router-dom";
import "../css/style.css";
import Header from "../layout/header.jsx";
import LeftMenu from "../layout/leftMenu.jsx";
import { backendApi, apiCallingPost } from "../apiService";
import $ from "jquery";
import * as Icon from "react-feather";
import Reviewimage from "../images/left_icons/review.png";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import DatePicker from "react-datepicker";
import { FaReply } from "react-icons/fa";
import ScaleLoader from "react-spinners/ScaleLoader";
import Delete from "../images/delete.png";
import Wordcloud from "wordcloud";
import "react-datepicker/dist/react-datepicker.css";
import WordCloud from "./wordCloud";
import { Popover, OverlayTrigger } from "react-bootstrap";
// import { Suspense } from "solid-js";
import bulbImg from "../images/bulb.png";
import { TagsInput } from "react-tag-input-component";
import "../css/social_default.css";
import "../css/social_streams.css";
import { pythonBackendApi } from "../apiService";
import Pagination from "react-js-pagination";

var removeWords = require("remove-words");
var XLSX = require("xlsx");
const reviewCountArray = ["ZERO", "ONE", "TWO", "THREE", "FOUR", "FIVE"];
const CountWordsArray = require("count-words-array");

let location_id = window.location.pathname.split("/")[2];
let titleName = window.location.pathname.split("/")[3];
let finalfilter = [];
let finalfilter1 = [];
let finalarray = [];
let outputArray = [];
let addressarray = [];
let dateFilter = [];
let reviewFilter = [];
let arrfilter = [];
let type = null;
let options = null;
class ApproveReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      file: null,
      buffer: null,
      convertText: "",
      sourceText: "",
      status: "",
      src: "",
      imageLanguage: "",
      imageLanguageLabel: "",
      translateLanguage: "",
      languageListImage: [],
      languageListTTS: [],
      uploaded: false,
      dataList: [],
      excelData: [],
      googleData: [],
      keyword: [],
      keywordLength: 0,
      reviewDatas: [],
      getCompanyData: [],
      companyName: "",
      displayName: "",
      createTime: "",
      comment: "",
      starRating: "",
      reviewList: [],
      rateChange: "",
      messageChange: "",
      reviewId: "",
      replyComments: "",
      radioComment: "",
      profilePhotoUrl: "",
      sentimentalList: [],
      revId: "",
      startDate: "",
      endDate: "",
      newRating: "",
      resultProductData: [],
      resultReviewarray: [],
      getlocationData: [],
      sentimentalDataList: [],
      allLocationdata: [],
      flagdata: [],
      getLocationAccount: [],
      getLocationTitle: [],
      getTitleName: "",
      getTitleNameCopy: "",
      getLocation: "",
      getCityName: "",
      getCityList: [],
      businessName: [],
      getbusinessList: "",
      getaccountByLocationList: [],
      starReview: "",
      reply: "",
      sentimental: "",
      templateNewState: [],
      reviewArray: [],
      globalFilterLocationsList: [],
      businessNameFilter: "",
      AdministrativeArea: "",
      LocalityList: "",
      tempSearchLocationsList: [],
      newReviewFilterList: [],
      offset: "",
      locationIdsList: [],
      totalCount: "",
      currentPage: 1,
    };
    this.setSelected = this.setSelected.bind(this);
    this.handleCompanyDetails = this.handleCompanyDetails.bind(this);
    this.editReview = this.editReview.bind(this);
  }
  componentDidMount() {
    $("#nodata").hide();
    $("#approve_popupmodal").hide();
    // this.pythonCodeReviewUpdateFunction();
    this.getreviewlist();
    this.getAdministrativeArea();
    this.newReviewList();
    this.getLocationSearch();
    this.pythonCodeReviewUpdateFunction();
    $("#templateView" + this.state.revId).hide();
    $("#worldcloud").hide();
  }

  getAdministrativeArea() {
    $(".loder_div").attr("hidden", false);
    const businessAccountData = {
      functionName: "getAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        this.setState({
          getLocationTitle: data.data.map((obj) => obj.AdministrativeArea),
        });
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  pythonCodeReviewUpdateFunction() {
    let date = new Date();
    let siteId =
      window.sessionStorage.getItem("switchingOrgId") === ""
        ? window.sessionStorage.getItem("organizationId")
        : window.sessionStorage.getItem("switchingOrgId");

    const apiReq = {
      method: "POST",
      body: JSON.stringify({
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
      }),
    };
    var ApiParameters = {
      functionName: "dataMigrationReviewAndQA",
      function_name: "every_time_review_update_by_site",
      module_name: "Approve Review Update By Site",
      params: { siteId: siteId, locationarray: "[{}]" },
    };
    if (window.sessionStorage?.getItem("ReviewUpdateTime" + siteId)) {
      let lastDate = new Date(
        window.sessionStorage?.getItem("ReviewUpdateTime" + siteId)
      );
      const differenceInMinutes = Math.floor((date - lastDate) / 60000);
      if (differenceInMinutes >= 30) {
        window.sessionStorage.setItem("ReviewUpdateTime" + siteId, date);
        // pythonBackendApi("QaaMigrationBySiteID", apiReq).then((data) => {
        //   console.log("data", data);
        // });
        backendApi(ApiParameters).then((data) => {
          console.log("data", data);
        });
      }
    } else {
      window.sessionStorage.setItem("ReviewUpdateTime" + siteId, date);
      backendApi(ApiParameters).then((data) => {
        console.log("data", data);
      });
      // pythonBackendApi("QaaMigrationBySiteID", apiReq).then((data) => {
      //   console.log("data", data);
      // });
    }
  }
  newReviewList() {
    $(".loder_div").attr("hidden", false);
    const apiReq = {
      functionName: "newReviewListApprove",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: this.state.getTitleName,
      fromDate:
        this.state.endDate != ""
          ? moment(this.state.endDate).format("yyyy-MM-DD")
          : "",
      toDate:
        this.state.startDate != ""
          ? moment(this.state.startDate).format("yyyy-MM-DD")
          : "",
      starValue: this.state.starReview,
      sentiment: "",
      administrativeArea: this.state.AdministrativeArea,
      locality: this.state.LocalityList,
      searchKeyword: this.state.keyword,
      offset: this.state.offset,
    };
    console.log("apiReq", apiReq);
    backendApi(apiReq).then((data) => {
      $(".loder_div").attr("hidden", true);
      let rating = ["ZERO", "ONE", "TWO", "THREE", "FOUR", "FIVE"];
      var final1 = data.data.filter((obj) => {
        if (obj.reviewReplyComment == "") {
          if (this.state.reviewList.length > 0) {
            let ratingValue = rating.indexOf(obj.starRating);
            let ratingComments = this.state.reviewList
              .filter((obj) => obj.star_rate == ratingValue)
              .sort((a, b) => 0.5 - Math.random());
            if (ratingComments.length > 0) {
              ratingComments = ratingComments[0].template;
            }
            obj.reviewReplyDefault = ratingComments;
            return obj;
          } else {
            return obj;
          }
        }
      });
      if (final1.length > 0) $("#nodata").show();
      console.log("final", final1);
      this.setState(
        {
          newReviewFilterList: final1,
          totalCount: data.totalCount[0].totalCount,
        },
        () => {
          window.animation(document.getElementById("grid_review"));
        }
      );
    });
  }

  getLocationSearch() {
    $(".loder_div").attr("hidden", false);
    const businessAccountData = {
      functionName: "getLocationSearch",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: "",
      locality: "",
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        this.setState({
          allLocationdata: data.data,
          businessName: data.data,
          // getTitleName: data.data[0].location_id,
          globalFilterLocationsList: data.data,
        });
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  newGetLocationReviews() {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "NewGetBatchReview",
      accountId: window.sessionStorage.getItem("account_id"),
      reqData: {
        locationNames: finalarray,
        pageSize: 50,
        orderBy: "updateTime desc",
        ignoreRatingOnlyReviews: true,
      },
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(reviewAPIData).then((data) => {
      //
      setTimeout(() => {
        $(".loder_div").attr("hidden", true);
      }, 500);
      let rating = ["ZERO", "ONE", "TWO", "THREE", "FOUR", "FIVE"];
      if (data.success == 1) {
        debugger;
        if (Object.keys(data.data).length == 0) {
          $("#nodata").show();
          this.setState({
            getlocationData: [],
            resultReviewarray: [],
            getlocationDataopy: [],
          });
          return false;
        } else {
          $("#nodata").hide();
          var final1 = data.data.locationReviews.filter((obj) => {
            if (
              Object.keys(obj.review).toString().indexOf("reviewReply") == "-1"
            ) {
              if (this.state.reviewList.length > 0) {
                let ratingValue = rating.indexOf(obj.review.starRating);
                let ratingComments = this.state.reviewList
                  .filter((obj) => obj.star_rate == ratingValue)
                  .sort((a, b) => 0.5 - Math.random());
                if (ratingComments.length > 0) {
                  ratingComments = ratingComments[0].template;
                }
                obj.reviewReplyDefault = ratingComments;
                return obj;
              } else {
                return obj;
              }
            } else if (
              Object.keys(obj.review).toString().indexOf("reviewReply") != "-1"
            ) {
              $("#nodata").show();
              console.log("im here....");
            }
          });
          console.log("11111111111final", final1);
          this.setState(
            {
              getlocationData: final1,
              resultReviewarray: final1,
              getlocationDataopy: final1,
            },
            () => {
              window.animation(document.getElementById("grid_review"));
            }
          );
          this.recallUpdate();
        }
      } else {
        this.setState({
          getlocationData: [],
          resultReviewarray: [],
          getlocationDataopy: [],
        });
        $("#nodata").show();
        $(".loder_div").attr("hidden", true);
      }
    });
  }

  handleChangeCity(value, AdministrativeArea) {
    console.log("hiiii", value);
    this.setState({
      businessNameFilter: "",
      LocalityList: value,
      businessAddress: "",
      getTitleName: "",
    });

    const businessAccountData = {
      functionName: "getLocationSearch",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: AdministrativeArea,
      locality: value,
    };
    $(".loder_div").attr("hidden", false);
    apiCallingPost(businessAccountData).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        this.setState({
          globalFilterLocationsList: data.data,
          tempSearchLocationsList: data.data,
          locationIdsList: data.data.map((obj) => obj.location_id),
        });
        finalarray = [];
        // data.data.forEach((data, i) => {
        //   if (i <= 7)
        //     finalarray.push(
        //       `accounts/${window.sessionStorage.getItem(
        //         "account_id"
        //       )}/locations/${data.location_id}`
        //     );
        // });
        // this.newGetLocationReviews();
      } else {
        this.setState({
          globalFilterLocationsList: [],
          tempSearchLocationsList: [],
          locationIdsList: [],
        });
        $(".loder_div").attr("hidden", true);
      }
    });
    // this.setState({
    //   getLocation: value,
    // });
    // this.setState({
    //   businessName: filterbusiness,
    // });
  }

  handleChangeState = (value) => {
    $("#cityId").val("");
    if (value !== "") {
      this.setState({
        businessNameFilter: "",
        businessAddress: "",
        LocalityList: "",
        getTitleName: "",
      });
      const businessAccountData = {
        functionName: "getLocalityUnderAdministrativeArea",
        account_id: window.sessionStorage.getItem("account_id"),
        AdministrativeArea: value,
      };
      $(".loder_div").attr("hidden", false);
      apiCallingPost(businessAccountData).then((data) => {
        $(".loder_div").attr("hidden", true);
        if (data.success === "1") {
          const businessAccountData = {
            functionName: "getLocationSearch",
            account_id: window.sessionStorage.getItem("account_id"),
            AdministrativeArea: value,
            locality: "",
          };
          backendApi(businessAccountData).then((response) => {
            this.setState({
              globalFilterLocationsList: response.data,
              tempSearchLocationsList: response.data,
              locationIdsList: response.data.map((obj) => obj.location_id),
            });
            finalarray = [];
            // response.data.forEach((data, i) => {
            //   if (i <= 7)
            //     finalarray.push(
            //       `accounts/${window.sessionStorage.getItem(
            //         "account_id"
            //       )}/locations/${data.location_id}`
            //     );
            // });
            // this.newGetLocationReviews();
          });
          this.setState({
            getCityList: data.data.map((obj) => obj.locality),
            AdministrativeArea: value,
          });
        } else {
          this.setState({
            getCityList: [],
          });
          $(".loder_div").attr("hidden", true);
        }
      });
    }
  };
  getStoreCode(locationId) {
    let address = this.state.allLocationdata
      .filter((data) => {
        return data.location_id == locationId;
      })
      .map((obj) => {
        return `${obj.storeCode}`;
      });
    // console.log("locationId", address[0]);
    return address[0];
  }
  getRating(rating) {
    if (rating === "") return 0;
    let ratingText = rating?.toUpperCase();
    switch (ratingText) {
      case "FIVE":
        return 5;
      case "FOUR":
        return 4;
      case "THREE":
        return 3;
      case "TWO":
        return 2;
      case "ONE":
        return 1;
    }
  }
  exportReveiw() {
    // Get the review data
    const reviews = this.state.newReviewFilterList;
    // Map the reviews to an array of objects with the required columns
    const data = reviews.map((review) => {
      console.log(
        "🚀 ~ file: approveReview.jsx:499 ~ ApproveReview ~ data ~ review:",
        review
      );
      let totalAddress = this.getAddress(review.location_id);
      return {
        "Store Code": this.getStoreCode(review.location_id),
        "Store Name": `${totalAddress[0].title}`,
        Address: `${totalAddress[0].addressLines}`,
        Locality: `${totalAddress[0].locality}`,
        State: `${totalAddress[0].AdministrativeArea}`,
        "Postal Code": `${totalAddress[0].postalCode}`,
        "Date and Time": moment(review.createTime).format("DD/MM/YYYY hh:mm A"),
        Name: review.displayName,
        Rating: this.getRating(review.starRating),
        Sentiment: this.getSentiment(review.starRating),
        Review: this.getReviewText(review),
        Reply:
          this.state.radioComment || review.reviewReplyComment === ""
            ? ""
            : review.reviewReplyComment,
        "Replied to review date":
          review.reviewReplyComment !== ""
            ? moment(review.reviewReplyUpdateTime).format("DD/MM/YYYY hh:mm A")
            : "",
      };
    });

    // Define the worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add a custom number format for the rating column
    worksheet["!cols"] = [
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
    ];
    // worksheet['!numfmt'] = [{}, {}, { numFmt: '[>=1][<2]★;General;General;@' }];
    worksheet["!cols"].splice(3, 0, { width: 20 }); // Add a column with width 20 at index 3 (after the 'Rating' column)

    // Add the worksheet to a new workbook and save it
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reviews");
    XLSX.writeFile(workbook, "reviews.xlsx");
  }
  getAddress(locationId) {
    let address2 = this.state.allLocationdata.filter((data) => {
      return data.location_id == locationId;
    });
    return address2;

    let address = this.state.allLocationdata
      .filter((data) => {
        return data.location_id == locationId;
      })
      .map(
        (obj) =>
          `${obj.addressLines}, ${obj.locality}, ${obj.AdministrativeArea}`
      );
    console.log("locationId", address[0]);
    return address[0];
  }
  getSentiment(review) {
    if (review.toString() == "FIVE" || review.toString() == "FOUR") {
      return "POSITIVE";
    } else if (review.toString() == "TWO" || review.toString() == "ONE") {
      return "NEGATIVE";
    } else if (review.toString() == "THREE") {
      return "NEUTRAL";
    } else {
      return ""; // Sentiment will be determined by another process
    }
  }

  getReviewText(review) {
    // Return the review text or an empty string if there is none
    return review.comment || "";
  }

  getlocationData() {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "GetBatchReview",
      accountId: window.sessionStorage.getItem("account_id"),
      reqData: {
        locationNames: finalarray,
        pageSize: 50,
        orderBy: "updateTime desc",
        ignoreRatingOnlyReviews: true,
      },
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(reviewAPIData).then((data) => {
      //
      setTimeout(() => {
        $(".loder_div").attr("hidden", true);
      }, 500);
      let rating = ["ZERO", "ONE", "TWO", "THREE", "FOUR", "FIVE"];
      if (data.success == 1) {
        debugger;
        if (Object.keys(data.data).length == 0) {
          $("#nodata").show();
          this.setState({
            getlocationData: [],
            resultReviewarray: [],
            getlocationDataopy: [],
          });
          return false;
        } else {
          $("#nodata").hide();
          var final1 = data.data.locationReviews.filter((obj) => {
            console.log(
              "keyyyys",
              Object.keys(obj.review).toString().indexOf("reviewReply")
            );
            // console.log("final1", final1);
            if (
              Object.keys(obj.review).toString().indexOf("reviewReply") == "-1"
            ) {
              if (this.state.reviewList.length > 0) {
                let ratingValue = rating.indexOf(obj.review.starRating);
                let ratingComments = this.state.reviewList
                  .filter((obj) => obj.star_rate == ratingValue)
                  .sort((a, b) => 0.5 - Math.random());
                if (ratingComments.length > 0) {
                  ratingComments = ratingComments[0].template;
                }
                obj.reviewReplyDefault = ratingComments;
                console.log("objobjobjobjif", obj);
                return obj;
              } else {
                console.log("objobjobjobjelse", obj);
                return obj;
              }
            } else if (
              Object.keys(obj.review).toString().indexOf("reviewReply") != "-1"
            ) {
              $("#nodata").show();
              console.log("im here....");
            }
          });
          console.log("11111111111final", final1);
          this.setState(
            {
              getlocationData: final1,
              resultReviewarray: final1,
              getlocationDataopy: final1,
            },
            () => {
              window.animation(document.getElementById("grid_review"));
            }
          );
          this.recallUpdate();
        }
      } else if (data.errorMessage != "") {
        this.setState({
          getlocationData: [],
          resultReviewarray: [],
          getlocationDataopy: [],
        });
        $("#nodata").show();
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  getClearData() {
    this.getLocationSearch();
    //
    // window.location.reload()
    // $("#stateId").val("");
    // $("#cityId").val("");
    // let businessName = this.state.allLocationdata.map((obj) => {
    //   return obj.title;
    // });
    this.setState(
      {
        startDate: "",
        endDate: "",
        starReview: "",
        sentimental: "",
        reply: "",
        getTitleName: "",
        getCityList: [],
        AdministrativeArea: "",
        keyword: [],
        LocalityList: "",
        businessNameFilter: "",
        locationIdsList: [],
        // businessName: businessName,
      },
      () => {
        this.newReviewList();
      }
    );
  }
  getBusinessChange(item, data) {
    let filterData = this.state.allLocationdata.filter((obj, i) => {
      return obj.location_id === item;
    });
    this.setState({
      getTitleName: filterData[0].location_id,
      getTitleNameCopy: filterData[0].title,
      locationIdsList: [filterData[0].location_id],
      businessNameFilter: `${filterData[0].storeCode}- ${filterData[0].title}-${filterData[0].addressLines},
      ${filterData[0].locality},${filterData[0].AdministrativeArea}`,
    });
  }
  recallUpdate() {
    const reviewAPIData = {
      functionName: "processSentiMental",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        this.setState({
          sentimentalList: data.data,
          sentimentalDataList: data.data,
        });
      }
    });
  }
  setSelected(val) {
    this.setState({
      keyword: val,
    });
  }
  handleCompanyDetails(e) {
    this.setState({ companyName: e.target.value });
  }
  templateShow(revId, starRating) {
    let tempStarRating =
      starRating === "ONE"
        ? 1
        : starRating === "TWO"
        ? 2
        : starRating === "THREE"
        ? 3
        : starRating === "FOUR"
        ? 4
        : starRating === "FIVE"
        ? 5
        : 0;
    this.setState({ revId: revId, starRating: tempStarRating }, () => {
      $("#templateView" + this.state.revId).show();
      $("#templateListAll" + this.state.revId).show();
      $("#newTemplate").hide();
    });
  }
  newTemplate() {
    $("#newTemplate").show();
    $("#templateListAll" + this.state.revId).hide();
  }
  editReview(message, templateId, starRate, index) {
    console.log(this.state.getlocationData, "aaaaaaaaaaaaaaaa", message, index);
    $('#ratedropdown [value="' + starRate + '"]').attr("selected", "true");
    $("#newTemplate").show();
    $("#templateListAll" + this.state.revId).hide();
    let data = [...this.state.newReviewFilterList];
    data[index].reviewReplyDefault = message;
    this.setState(
      {
        messageChange: message,
        templateId: templateId,
        rateChange: starRate,
        getlocationData: data,
      },
      () => {
        window.animation(document.getElementById("grid_review"));
      }
    );
  }
  textfield(e, index) {
    $("#abcd" + index).css("display", "block");
    console.log("eeeeeeeee", e);
    let data = [...this.state.newReviewFilterList];
    console.log("eeeeeeeee", data[index]);
    data[index].reviewReplyDefault = e;
    this.setState(
      {
        newReviewFilterList: data,
      },
      () => {
        window.animation(document.getElementById("grid_review"));
      }
    );
  }
  templateViewClose() {
    $("#templateView" + this.state.revId).hide();
  }
  getreviewlist() {
    var apidata = {
      functionName: "reviewTemplateListing",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apidata).then((data) => {
      console.log("data", data);
      if (data.success === "1") {
        let abc = [];
        for (let i = 0; i < data.result.length; i++) {
          abc.push(data.result[i].star_rate);
        }
        const tofindDuplicates = (abc) =>
          abc.filter((item, index) => abc.indexOf(item) !== index);
        const duplicateElementa = tofindDuplicates(abc);
        this.setState({
          reviewList: data.result,
          reviewArray: duplicateElementa,
        });
      }
    });
  }
  backReview() {
    $("#templateView" + this.state.revId).show();
    $("#templateListAll" + this.state.revId).show();
    $("#newTemplate").hide();
  }
  saveTemplate() {
    console.log("this.state.templateId", this.state.templateId);
    var apidata = {
      functionName: "reviewTemplateSave",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      template: this.state.messageChange,
      starRate: this.state.rateChange,
      templateId:
        this.state.templateId === undefined ? "" : this.state.templateId,
    };
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        if (this.state.templateId == undefined) {
          alert("Templated added Successfully");
          $("#templateView" + this.state.revId).hide();
          this.getreviewlist();
        } else {
          alert("Templated Updated Successfully");
          $("#templateView" + this.state.revId).hide();
          this.getreviewlist();
        }
      } else {
        return false;
      }
    });
  }
  deleteReview(Id) {
    if (Id != "") {
      var apidata = {
        templateId: Id,
        functionName: "reviewTemplateDelete",
      };
      backendApi(apidata).then((data) => {
        if (data.success === "1") {
          alert("Templated deleted Successfully");
          this.getreviewlist();
        } else {
          return false;
        }
      });
    }
  }
  replaySave(i, reviewId, name, comment) {
    $("#approve_popupmodal" + i).hide();
    const reviewAPIData = {
      functionName: "ReplyToReview",
      comment: comment,
      reviewID: reviewId,
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: name.split("/")[3],
    };
    const reviewSaveData = {
      functionName: "newreviewReplyCommentUpdate",
      reviewId: reviewId,
      reviewReplyComment: comment,
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        apiCallingPost(reviewSaveData).then((obj) => {
          if (data.success == 1) {
            alert("Response Posted Successfully");
            $("body").removeClass("newreview_visible");
            this.getreviewlist();
            this.newReviewList();
            $("#deleteid" + reviewId).show();
          } else {
            alert("Something went wrong while saving reply");
          }
        });
      } else {
        alert("Your Reply was not Reviewed");
      }
    });
  }
  replayDelete(reviewId, name, comment) {
    if (!window.confirm("Do you want to delete this reply?")) {
      return false;
    }
    const reviewAPIData = {
      functionName: "DeleteReply",
      reviewID: reviewId,
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: location_id,
    };
    // const reviewDelete = {
    //   functionName: "newDeleteReview",
    //   reviewId: reviewId,
    // };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        alert("Successfully Deleted the post");
        $("body").removeClass("newreview_visible");
        $("#deleteid" + reviewId).hide();
        this.newReviewList();
        $("#replymessage" + reviewId).val("");
      } else {
        alert("Error on Deleting the post");
      }
    });
  }
  selectTemplate(template) {
    this.setState({
      radioComment: template,
    });
    $("#replymessage" + this.state.revId).val(template);
    $("#templateView" + this.state.revId).hide();
  }
  ratingChanged(newRating) {
    this.setState({
      newRating: newRating,
    });
  }
  handleChangeStart(value) {
    this.setState({
      startDate: value,
    });
  }
  handleChangeEnd(values) {
    this.setState({
      endDate: values,
    });
  }
  star(values) {
    // alert(values)
    this.setState({
      starReview: values,
    });
  }
  replyDrop(values) {
    // alert(values)
    this.setState({
      reply: values,
    });
  }
  sentimentalDrop(values) {
    // alert(values)
    this.setState({
      sentimental: values,
    });
  }
  reviewChange(locationId) {
    window.location.href =
      "/review/" + "53904911361864879" + "/" + "Xilligence";
    // const reviewAPIData = {
    //   functionName: "GetLocationsByAccountId",
    //   accountId: window.sessionStorage.getItem("account_id"),
    //   siteId:
    //     window.sessionStorage.getItem("switchingOrgId") === ""
    //       ? window.sessionStorage.getItem("organizationId")
    //       : window.sessionStorage.getItem("switchingOrgId"),
    // };
    // apiCallingPost(reviewAPIData).then((data) => {
    //   if (data.data.locations.length > 0) {
    //     for (let i = 0; i < data.data.locations.length; i++) {
    //       //  console.log("data.data.locations",data.data.locations[i].name.split("/")[1])
    //       if (locationId == data.data.locations[i].name.split("/")[1]) {
    //         window.location.href =
    //           "/review/" + locationId + "/" + data.data.locations[i].title;
    //       }
    //     }
    //     // this.setState({ getReviewsLocation: data.data.locations });
    //   }
    // });
  }
  worldCloudClose() {
    $("#worldcloud").hide();
  }
  worldCloudOpen() {
    $("#worldcloud").show();
    $(".loder_div").attr("hidden", false);
    $(".loder_div").attr("hidden", true);
    let reviews = this.state.resultReviewarray;
    if (reviews.length > 0) {
      let array = [];
      for (let i = 0; i < reviews.length; i++) {
        if (reviews[i].review.comment) {
          array.push(reviews[i].review.comment);
        }
      }
      console.log(">>>>>>>>>>>>>>>>>", array);
      let dataSet = removeWords(array.join());
      let finalData = "";
      dataSet.map((i) => {
        finalData = finalData + "," + i;
      });
      let countWordsArray = CountWordsArray(finalData);
      countWordsArray = countWordsArray.sort(function (a, b) {
        return b.count - a.count;
      });
      console.log("countWordsArray", countWordsArray);
      let final = countWordsArray;
      if (countWordsArray.length > 50) {
        final = countWordsArray.slice(1, 50);
      } else {
        final = countWordsArray;
      }
      console.log("final.....", final.map(Object.values));
      let finalDa = final.map(Object.values);
      let bbb = [
        ["foo12", 12],
        ["bar222", 2],
        ["bar555", 5],
        ["barwwww99", 9],
        ["bar44", 4],
      ];
      Wordcloud(document.getElementById("my_canvas"), {
        list: finalDa,
        gridSize: Math.round((16 * $("#my_canvas").width()) / 1024),
        weightFactor: 16.1,
        fontFamily: "Trebuchet MS sans-serif",
      });
    }
  }
  showVisible(id, review) {
    $('[aria-describedby="popover-trigger-click-root-close"]').removeClass(
      "none"
    );
    let ratings = ["ZERO", "ONE", "TWO", "THREE", "FOUR", "FIVE"];
    let ratingValues = ratings.indexOf(id);
    let finalReviewfilter = this.state.reviewList.filter((obj) => {
      return obj.star_rate == ratingValues;
    });
    //console.log("finalReviewfilter", finalReviewfilter);
    if (review.comment) {
      $(".loder_div").attr("hidden", false);
      var apidata = {
        method: "POST",
        body: JSON.stringify({ reviewText: review.comment }),
      };
      pythonBackendApi("reviewResFromGPT", apidata).then((data) => {
        if (data.success == "1") {
          $(".loder_div").attr("hidden", true);
          //console.log("Response from reviewResFromGPT:", data);
          this.setState((obj) => ({
            reviewFilter: [
              ...obj.reviewFilter,
              {
                id: "",
                siteId:
                  window.sessionStorage.getItem("switchingOrgId") === ""
                    ? window.sessionStorage.getItem("organizationId")
                    : window.sessionStorage.getItem("switchingOrgId"),
                template: data.result,
                star_rate: finalReviewfilter[0].star_rate,
                is_active: 1,
              },
            ],
          }));
          // finalReviewfilter.push({
          //   id: "",
          //   siteId:
          //     window.sessionStorage.getItem("switchingOrgId") === ""
          //       ? window.sessionStorage.getItem("organizationId")
          //       : window.sessionStorage.getItem("switchingOrgId"),
          //   template: data.result,
          //   star_rate: finalReviewfilter[0].star_rate,
          //   is_active: 1,
          // });
        } else {
          $(".loder_div").attr("hidden", true);
          //alert("error");
          //console.log("data", data);
        }
      });
    }
    this.setState({
      reviewFilter: finalReviewfilter,
    });
  }
  approvePopupshow(i, id) {
    if ($("#replymessage" + id).val() == "") {
      return false;
    } else {
      let data = [...this.state.newReviewFilterList];
      data[i].reviewReplyDefault = $("#replymessage" + id).val();
      this.setState(
        {
          getlocationData: data,
        },
        () => {
          console.log("replymessagereplymessage", this.state.getlocationData);
          $("#approve_popupmodal" + i).show();
          $("#approve_popupmodal" + i).attr("hidden", false);
        }
      );
      // alert(i)
    }
  }
  CloseAprovePopup(i) {
    $("#approve_popupmodal" + i).hide();
  }
  Locationchange() {
    window.sessionStorage.setItem("reviewrouter", "/review/approve");
    window.location.href = "/review/newtemplate";
  }
  filterLocations(e) {
    this.setState({
      businessNameFilter: e.target.value,
    });
    var array = [];
    if (this.state.AdministrativeArea != "" && this.state.LocalityList != "") {
      // this.setState({
      //   globalFilterLocationsList: this.state.tempSearchLocationsList,
      // });
      array = this.state.tempSearchLocationsList;
    } else {
      array = this.state.allLocationdata;
      // this.setState({
      //   globalFilterLocationsList: this.state.allLocationdata
      // });
    }
    var filterArray = array.filter(
      (data) =>
        data.storeCode.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.addressLines
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (
          data.storeCode +
          "," +
          data.addressLines +
          "," +
          data.locality +
          "," +
          data.AdministrativeArea
        )
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (data.locality + "," + data.AdministrativeArea)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );
    this.setState({
      globalFilterLocationsList: filterArray,
    });
  }
  NewSearchAPIFunction() {
    // const apiReq = {
    //   method: "POST",
    //   body: JSON.stringify({
    //     location_id: this.state.getTitleName,
    //   }),
    // };
    // pythonBackendApi("updateReviewByLocation", apiReq).then((data) => {
    // console.log("hiiiiii", data);
    this.reviewMigrateByLocation();
    this.newReviewList();
    // });
  }
  reviewMigrateByLocation() {
    if (this.state.locationIdsList.length > 0) {
      // let apiReq = {
      //   method: "POST",
      //   body: JSON.stringify({
      //     siteId:
      //       window.sessionStorage.getItem("switchingOrgId") === ""
      //         ? window.sessionStorage.getItem("organizationId")
      //         : window.sessionStorage.getItem("switchingOrgId"),
      //     locationarray: `[{${this.state.locationIdsList.join(",")}}]`,
      //   }),
      // };
      let apiParameters = {
        functionName: "dataMigrationReviewAndQA",
        function_name: "every_time_review_update_byLocations",
        module_name: "Approve Review Update By Location",
        params: {
          siteId:
            window.sessionStorage.getItem("switchingOrgId") === ""
              ? window.sessionStorage.getItem("organizationId")
              : window.sessionStorage.getItem("switchingOrgId"),
          locationarray: `[{${this.state.locationIdsList.join(",")}}]`,
        },
      };
      // console.log("apiReqapiReq", apiReq);
      backendApi(apiParameters).then((data) => {
        console.log(data);
      });
      // pythonBackendApi("every_time_review_update_byLocations", apiReq);
    }
  }
  //  getBusinessMatch(review) {
  //   // Return the review text or an empty string if there is none
  //   return review.review.comment || '';
  // }
  handleActivePage(pageNumber) {
    // this.setState({})
    let offset = "";
    if (pageNumber == "1") {
      offset = "";
    } else {
      offset = parseInt(pageNumber - 1) * 50;
    }
    this.setState(
      {
        offset: offset,
        currentPage: pageNumber,
      },
      () => {
        this.newReviewList();
      }
    );
  }
  render() {
    //   const  popoverClick = (
    //       <Popover id="popover-trigger-click-root-close" title="Popover bottom">
    // <div class="popup_overlay">
    //   	<div class="popup_overlayhead">Heading</div>
    //       <div class="popup_overbody">
    //       	<div class="popup_bodycontt">first</div>
    //       	<div class="popup_bodycontt">Second</div>
    //       </div>
    //       </div>
    //       </Popover>
    //     );
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header
            heading="Google Reviews"
            subheading="Approve & Post"
            headerImage={Reviewimage}
          />
          <div className="container-fluid">
            <div className="row">
              <div className="col text-right">
                {/* {this.state.getlocationData.length > 0 && ( */}
                <button
                  type="button"
                  className="btn_worldclouds ml-2"
                  onClick={() => this.exportReveiw()}
                >
                  Export
                </button>
                {/* )} */}
                <a onClick={() => this.Locationchange()}>
                  <button class="replay_tembtn ml-2">Template</button>
                </a>
                <button
                  type="button"
                  className="btn_worldcloud ml-2"
                  onClick={() => this.worldCloudOpen()}
                >
                  Word Cloud
                </button>
              </div>
            </div>
            <div className="row row-search px-2 mt-2">
              {/* <div className="col-lg-2 col-md-4 col-sm-6">
                  <select className="form-control">
                  <option value="">India</option>
                  </select>
                </div> */}
              <div className="col-lg-2 col-md-4 col-sm-6">
                {console.log("xxxxxxxxxxxx", addressarray)}
                <select
                  className="form-control"
                  id="stateId"
                  value={this.state.AdministrativeArea}
                  onChange={(e) => this.handleChangeState(e.target.value, 1)}
                >
                  <option value="">State</option>
                  {this.state.getLocationTitle.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control"
                  id="cityId"
                  value={this.state.LocalityList}
                  onChange={(e) =>
                    this.handleChangeCity(
                      e.target.value,
                      this.state.AdministrativeArea,
                      0
                    )
                  }
                >
                  <option value="">City</option>
                  {this.state.getCityList.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              {/* <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control"
                  id="buisnessId"
                  value={this.state.getTitleName}
                  onChange={(e) => this.getBusinessChange(e.target.value)}
                >
                  <option value="0">Location</option>
                  {this.state.businessName.map((item) => (
                    <option value={item.location_id}>{item.storeCode}-{item.title}-{item.addressLines}</option>
                  ))}
                </select>
              </div> */}
              <div className="col-lg-5 col-md-4 location_search">
                <input
                  className="form-control"
                  value={this.state.businessNameFilter}
                  onChange={(e) => this.filterLocations(e)}
                  placeholder="Search by location"
                />
                <div className="autocomplete__list location_search_drop">
                  <ul class="nav flex-column">
                    {this.state.globalFilterLocationsList.map((data) => (
                      <li
                        key={data.location_id}
                        onMouseDown={() =>
                          this.getBusinessChange(data.location_id, data)
                        }
                      >
                        {data.storeCode}- {data.title}-{data.addressLines},
                        {data.locality},{data.AdministrativeArea}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control review_select_option"
                  id="starId"
                  value={this.state.starReview}
                  onChange={(e) => this.star(e.target.value)}
                >
                  <option value="">Review</option>
                  <option value="ONE">
                    <span>&#9733;</span>
                  </option>
                  <option value="TWO">
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                  </option>
                  <option value="THREE">
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                  </option>
                  <option value="FOUR">
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                  </option>
                  <option value="FIVE">
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                  </option>
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <DatePicker
                  selected={this.state.startDate}
                  onChange={(date) => this.handleChangeStart(date)}
                  name="startDate"
                  maxDate={new Date()}
                  showMonthDropdown
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  placeholderText="From Date"
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <DatePicker
                  selected={this.state.endDate}
                  onChange={(date) => this.handleChangeEnd(date)}
                  name="endDate"
                  maxDate={new Date()}
                  id="endDate"
                  showMonthDropdown
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  placeholderText="To Date"
                />
              </div>
              {/* <div className="col-lg-2 col-md-4 col-sm-6 location_search">
                <input className="form-control" type="text" placeholder="Location" />
                <div className="location_search_drop">
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <span>Location1</span>
                    </li>
                    <li class="nav-item">
                      <span>Location2</span>
                    </li>
                    <li class="nav-item">
                      <span>Location3</span>
                    </li>
                    <li class="nav-item">
                      <span>Location4</span>
                    </li>
                    <li class="nav-item">
                      <span>Location5</span>
                    </li>
                    <li class="nav-item">
                      <span>Location6</span>
                    </li>
                  </ul>
                </div>
              </div> */}

              <div className="col-lg-5 tagsInput">
                <TagsInput
                  // key={this.state.keywordLength}
                  value={this.state.keyword}
                  onChange={this.setSelected}
                  name="keywords"
                  isEditOnRemove={true}
                  placeHolder="Write and press ENTER after each word"
                  style={{ width: "100%" }}
                />
              </div>
              {/* <div className="col-lg-2 col-md-4 col-sm-6">
                <select className="form-control" id="replyId" value={this.state.reply} onChange={(e) => this.replyDrop(e.target.value)}>
                  <option value="">Reply</option>
                  <option value="Replied">Replied</option>
                  <option value="NotReplied">Not Replied</option>
                </select>
              </div> */}
              {/* <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control"
                  id="sentimentId"
                  value={this.state.sentimental}
                  onChange={(e) => this.sentimentalDrop(e.target.value)}
                >
                  <option value="">Sentiment</option>
                  <option value="POSITIVE">POSITIVE</option>
                  <option value="NEUTRAL">NEUTRAL</option>
                  <option value="NEGATIVE">NEGATIVE</option>
                  <option value="BLANK">BLANK</option>
                </select>
              </div> */}
              <div className="col-lg-1 col-md-4 col-sm-6 pr-0">
                <button
                  className="btn-search w-100"
                  onClick={() => this.NewSearchAPIFunction()}
                >
                  Search
                </button>
              </div>
              <div className="col-lg-1 col-md-4 col-sm-6 pr-0">
                <button
                  className="btn-clear-search w-100"
                  onClick={() => this.getClearData()}
                >
                  Clear
                </button>
              </div>
            </div>
            {/* <div className="text-right px-3">
              {this.state.reviewDatas.length > 0 && (
                <>
              <a
                onClick={() => this.Locationchange()}><button class="replay_tembtn">Template</button></a>
              <button
                type="button"
                className="btn btn-info ml-2"
                onClick={() => this.worldCloudOpen()}
              >
                Word Cloud
              </button>
              </>
              )} 
            </div> */}
            <div className="user_main_review">
              <div className="pagination_main">
                <Pagination
                  hideDisabled
                  activePage={this.state.currentPage}
                  totalItemsCount={this.state.totalCount}
                  itemsCountPerPage={50}
                  onChange={(e) => this.handleActivePage(e)}
                />
              </div>
              <div className="row d-flex align-items-center mx-0">
                {/* <div className="col-md-4">
                  <input
                    type="text"
                    id="companyName"
                    className="form-control"
                    onChange={this.handleCompanyDetails}
                    defaultValue={titleName}
                  />
                </div> */}
                {/* <div className="col-md-3">
                  <button
                    onClick={() => this.getCompanyData()}
                    className="btn btn-primary featch_btn"
                  >
                    Fetch
                  </button>
                </div> */}
              </div>
            </div>
            {/* <!------------------------------ Start Review Container------------------------------------> */}
            <ul className="row review_container grid effect-1" id="grid_review">
              {this.state.newReviewFilterList.length > 0 ? (
                this.state.newReviewFilterList.map((item, i) => (
                  <li className="col-lg-6">
                    <div
                      className={
                        item.isDeleted == 1
                          ? "row row_bodyview reviewIndexDeleted"
                          : "row row_bodyview"
                      }
                    >
                      <div className="col-md-6">
                        <div className="review_rowdiv">
                          <div class="review_rowname">Name</div>
                          <div class="review_rowfield">
                            {" "}
                            {item.displayName}{" "}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="review_rowdiv">
                          <div class="review_rowname">Date and Time</div>
                          <div class="review_rowfield">
                            {" "}
                            {moment(item.createTime).format(
                              "DD/MM/YYYY hh:mm A"
                            )}{" "}
                            {/* {moment(item.review.createTime).format("hh:mm A")} */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="review_rowdiv mb-0">
                          <div class="review_rowreview">Review</div>
                          <div class="review_rowfield">
                            <ReactStars
                              key={item.starRating}
                              count={
                                item.starRating === "ONE"
                                  ? 1
                                  : item.starRating === "TWO"
                                  ? 2
                                  : item.starRating === "THREE"
                                  ? 3
                                  : item.starRating === "FOUR"
                                  ? 4
                                  : item.starRating === "FIVE"
                                  ? 5
                                  : 0
                              }
                              size={24}
                              edit={false}
                              color2={"#ffd700"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="review_rowdiv mb-0">
                          <div class="review_rowname">Sentiment</div>
                          <div class="review_rowfield">
                            {(item.starRating == "FIVE" &&
                              item.reviewReplyComment == "") ||
                            (item.starRating == "FOUR" &&
                              item.reviewReplyComment == "") ? (
                              <span
                                className={
                                  (item.starRating === "FIVE" &&
                                    item.reviewReplyComment == "") ||
                                  (item.starRating == "FOUR" &&
                                    item.reviewReplyComment == "")
                                    ? "review_mixedvalue review_green"
                                    : ""
                                }
                              >
                                POSITIVE
                              </span>
                            ) : (item.starRating == "ONE" &&
                                item.reviewReplyComment == "") ||
                              (item.starRating == "TWO" &&
                                item.reviewReplyComment == "") ? (
                              <span
                                className={
                                  (item.starRating === "ONE" &&
                                    item.reviewReplyComment == "") ||
                                  (item.starRating == "TWO" &&
                                    item.reviewReplyComment == "")
                                    ? "review_mixedvalue review_red"
                                    : ""
                                }
                              >
                                NEGATIVE
                              </span>
                            ) : item.starRating == "THREE" &&
                              item.reviewReplyComment == "" ? (
                              <span
                                className={
                                  item.starRating === "THREE" &&
                                  item.reviewReplyComment == ""
                                    ? "review_mixedvalue review_yellow"
                                    : ""
                                }
                              >
                                NEUTRAL
                              </span>
                            ) : (
                              <span>
                                {this.state.sentimentalList.length > 0
                                  ? this.state.sentimentalList
                                      .filter(
                                        (obj) => obj.review_id == item.reviewId
                                      )
                                      .map((data) => (
                                        <span key={data.status} className="">
                                          <span
                                            className={
                                              data.status === "POSITIVE"
                                                ? "review_mixedvalue review_green"
                                                : data.status == "NEUTRAL"
                                                ? "review_mixedvalue review_yellow"
                                                : data.status == "NEGATIVE"
                                                ? "review_mixedvalue review_red"
                                                : ""
                                            }
                                          >
                                            {data.status}
                                          </span>
                                          {(item.starRating === "FIVE" ||
                                            item.starRating === "TWO" ||
                                            item.starRating === "ONE") &&
                                          item.comment != "" &&
                                          data.status === "" ? (
                                            <ScaleLoader
                                              color="#36d7b7"
                                              height={20}
                                              width={2}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      ))
                                  : ""}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="review_rowaddress">
                          <div class="review_rowadddiv">Address</div>
                          <div class="review_rowcommd">
                            <p>
                              {this.state.allLocationdata
                                .filter((data) => {
                                  return data.location_id === item.location_id;
                                })
                                .map((obj) => {
                                  return (
                                    <>
                                      {obj.storeCode}-{obj.title}
                                    </>
                                  );
                                })}
                            </p>
                            <p>
                              {this.state.allLocationdata
                                .filter((data) => {
                                  return data.location_id === item.location_id;
                                })
                                .map((obj) => {
                                  return (
                                    <>
                                      {obj.addressLines},{obj.locality},
                                      {obj.AdministrativeArea}
                                    </>
                                  );
                                })}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-2">
                        {item.comment == "" ? (
                          ""
                        ) : (
                          <>
                            <div class="review_rowadddiv">Review</div>
                            <div className="review_replaydiv">
                              {item.comment}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-md-12">
                        <div class="review_rowadddiv w-100">Reply</div>
                        <div className="all_review_divtag text_araa_div">
                          <div className="textarea_replydiv">
                            <textarea
                              placeholder="Reply publicly"
                              value={item.reviewReplyDefault}
                              rows="3"
                              id={"replymessage" + item.reviewId}
                              onChange={(e) =>
                                this.textfield(e.target.value, i)
                              }
                            ></textarea>
                            <div className="round_divreply">
                              <Icon.Send
                                id={"abcd" + i}
                                className="text-primary"
                                onClick={(e) =>
                                  this.approvePopupshow(i, item.reviewId, item)
                                }
                              />
                            </div>
                          </div>
                          <div className="review_allreplybtn">
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement={
                                window.innerWidth < 992 ? "bottom" : "left"
                              }
                              overlay={
                                <Popover id="popover-trigger-click-root-close">
                                  <div
                                    class={
                                      "popup_overlay" +
                                      (window.innerWidth < 992
                                        ? " bottom"
                                        : i % 2 == 0
                                        ? " right"
                                        : " left")
                                    }
                                  >
                                    <div class="popup_overlayhead d-flex align-item-center">
                                      Template
                                      <span className="ml-auto">
                                        <Icon.X
                                          width="16"
                                          onClick={() => document.body.click()}
                                        />
                                      </span>
                                    </div>
                                    <div
                                      class="popup_overbody"
                                      onClick={() => document.body.click()}
                                    >
                                      {this.state.reviewFilter?.length > 0 &&
                                        this.state.reviewFilter.map(
                                          (item, j) => (
                                            <div
                                              class="popup_bodycontt"
                                              onClick={() =>
                                                this.editReview(
                                                  item.template,
                                                  item.id,
                                                  item.star_rate,
                                                  i
                                                )
                                              }
                                            >
                                              {item.template}
                                            </div>
                                          )
                                        )}
                                    </div>
                                  </div>
                                </Popover>
                              }
                            >
                              <div>
                                <button
                                  className={
                                    "overlay_reviewdiv rat_" + item.starRating
                                  }
                                  onClick={() => {
                                    this.showVisible(item.starRating, item);
                                  }}
                                >
                                  <img src={bulbImg} />
                                </button>
                              </div>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <h3 id="nodata" className="px-2 w-100 text-center noDataFound">
                  No Data Found
                </h3>
              )}
            </ul>
            <div className="user_main_review">
              <div className="pagination_main">
                <Pagination
                  hideDisabled
                  activePage={this.state.currentPage}
                  itemsCountPerPage={50}
                  totalItemsCount={this.state.totalCount}
                  onChange={(e) => this.handleActivePage(e)}
                />
              </div>
            </div>
            {/* <ul className="row review_container grid effect-1" id="grid_review">
              {this.state.getlocationData.length > 0 ? (
                this.state.getlocationData.map((item, i) => (
                  <li className="col-lg-6">
                    <div className="row row_bodyview">
                      <div className="col-md-6">
                        <div className="review_rowdiv">
                          <div class="review_rowname">Name</div>
                          <div class="review_rowfield">
                            {" "}
                            {item.review.reviewer.displayName}{" "}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="review_rowdiv">
                          <div class="review_rowname">Date and Time</div>
                          <div class="review_rowfield">
                            {" "}
                            {moment(item.review.createTime).format(
                              "DD/MM/YYYY"
                            )}{" "}
                            {moment(item.review.createTime).format("hh:mm A")}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="review_rowdiv mb-0">
                          <div class="review_rowreview">Review</div>
                          <div class="review_rowfield">
                            <ReactStars
                              key={item.review.starRating}
                              count={
                                item.review.starRating === "ONE"
                                  ? 1
                                  : item.review.starRating === "TWO"
                                  ? 2
                                  : item.review.starRating === "THREE"
                                  ? 3
                                  : item.review.starRating === "FOUR"
                                  ? 4
                                  : item.review.starRating === "FIVE"
                                  ? 5
                                  : 0
                              }
                              size={24}
                              edit={false}
                              color2={"#ffd700"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="review_rowdiv mb-0">
                          <div class="review_rowname">Sentiment</div>
                          <div class="review_rowfield">
                            {(item.review.starRating == "FIVE" &&
                              Object.keys(item.review)
                                .toString()
                                .indexOf("comment") == "-1") ||
                            (item.review.starRating == "FOUR" &&
                              Object.keys(item.review)
                                .toString()
                                .indexOf("comment") == "-1") ? (
                              <span
                                className={
                                  (item.review.starRating === "FIVE" &&
                                    Object.keys(item.review)
                                      .toString()
                                      .indexOf("comment") == "-1") ||
                                  (item.review.starRating == "FOUR" &&
                                    Object.keys(item.review)
                                      .toString()
                                      .indexOf("comment") == "-1")
                                    ? "review_mixedvalue review_green"
                                    : ""
                                }
                              >
                                POSITIVE
                              </span>
                            ) : (item.review.starRating == "ONE" &&
                                Object.keys(item.review)
                                  .toString()
                                  .indexOf("comment") == "-1") ||
                              (item.review.starRating == "TWO" &&
                                Object.keys(item.review)
                                  .toString()
                                  .indexOf("comment") == "-1") ? (
                              <span
                                className={
                                  (item.review.starRating === "ONE" &&
                                    Object.keys(item.review)
                                      .toString()
                                      .indexOf("comment") == "-1") ||
                                  (item.review.starRating == "TWO" &&
                                    Object.keys(item.review)
                                      .toString()
                                      .indexOf("comment") == "-1")
                                    ? "review_mixedvalue review_red"
                                    : ""
                                }
                              >
                                NEGATIVE
                              </span>
                            ) : item.review.starRating == "THREE" &&
                              Object.keys(item.review)
                                .toString()
                                .indexOf("comment") == "-1" ? (
                              <span
                                className={
                                  item.review.starRating === "THREE" &&
                                  Object.keys(item.review)
                                    .toString()
                                    .indexOf("comment") == "-1"
                                    ? "review_mixedvalue review_yellow"
                                    : ""
                                }
                              >
                                NEUTRAL
                              </span>
                            ) : (
                              <span>
                                {this.state.sentimentalList.length > 0
                                  ? this.state.sentimentalList
                                      .filter(
                                        (obj) =>
                                          obj.review_id == item.review.reviewId
                                      )
                                      .map((data) => (
                                        <span key={data.status} className="">
                                          <span
                                            className={
                                              data.status === "POSITIVE"
                                                ? "review_mixedvalue review_green"
                                                : data.status == "NEUTRAL"
                                                ? "review_mixedvalue review_yellow"
                                                : data.status == "NEGATIVE"
                                                ? "review_mixedvalue review_red"
                                                : ""
                                            }
                                          >
                                            {data.status}
                                          </span>
                                          {(item.review.starRating === "FIVE" ||
                                            item.review.starRating === "TWO" ||
                                            item.review.starRating === "ONE") &&
                                          item.review.comment != "" &&
                                          data.status === "" ? (
                                            <ScaleLoader
                                              color="#36d7b7"
                                              height={20}
                                              width={2}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      ))
                                  : ""}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="review_rowaddress">
                          <div class="review_rowadddiv">Address</div>
                          <div class="review_rowcommd">
                            <p>
                              {this.state.allLocationdata
                                .filter((data) => {
                                  return (
                                    data.location_id === item.name.split("/")[3]
                                  );
                                })
                                .map((obj) => {
                                  return (
                                    <>
                                      {obj.storeCode}-{obj.title}
                                    </>
                                  );
                                })}
                            </p>
                            <p>
                              {this.state.allLocationdata
                                .filter((data) => {
                                  return (
                                    data.location_id === item.name.split("/")[3]
                                  );
                                })
                                .map((obj) => {
                                  return (
                                    <>
                                      {obj.addressLines},{obj.locality},
                                      {obj.AdministrativeArea}
                                    </>
                                  );
                                })}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-2">
                        {item.reviewReplyComment == "" ? (
                          ""
                        ) : (
                          <>
                            <div class="review_rowadddiv">Review</div>
                            <div className="review_replaydiv">
                              {item.comment}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-md-12">
                        <div class="review_rowadddiv w-100">Reply</div>
                        <div className="all_review_divtag text_araa_div">
                          <div className="textarea_replydiv">
                            <textarea
                              placeholder="Reply publicly"
                              value={item.reviewReplyDefault}
                              rows="3"
                              id={"replymessage" + item.reviewId}
                              onChange={(e) =>
                                this.textfield(e.target.value, i)
                              }
                            ></textarea>
                            <div className="round_divreply">
                              <Icon.Send
                                className="text-primary"
                                onClick={(e) =>
                                  this.approvePopupshow(i, item.review.reviewId)
                                }
                              />
                            </div>
                          </div>
                          <div className="review_allreplybtn">
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement={
                                window.innerWidth < 992 ? "bottom" : "left"
                              }
                              overlay={
                                <Popover id="popover-trigger-click-root-close">
                                  <div
                                    class={
                                      "popup_overlay" +
                                      (window.innerWidth < 992
                                        ? " bottom"
                                        : i % 2 == 0
                                        ? " right"
                                        : " left")
                                    }
                                  >
                                    <div class="popup_overlayhead d-flex align-item-center">
                                      Template
                                      <span className="ml-auto">
                                        <Icon.X
                                          width="16"
                                          onClick={() => document.body.click()}
                                        />
                                      </span>
                                    </div>
                                    <div
                                      class="popup_overbody"
                                      onClick={() => document.body.click()}
                                    >
                                      {this.state.reviewFilter?.length > 0 &&
                                        this.state.reviewFilter.map(
                                          (item, j) => (
                                            <div
                                              class="popup_bodycontt"
                                              onClick={() =>
                                                this.editReview(
                                                  item.template,
                                                  item.id,
                                                  item.star_rate,
                                                  i
                                                )
                                              }
                                            >
                                              {item.template}
                                            </div>
                                          )
                                        )}
                                    </div>
                                  </div>
                                </Popover>
                              }
                            >
                              <div>
                                <button
                                  className={
                                    "overlay_reviewdiv rat_" +
                                    item.review.starRating
                                  }
                                  onClick={() => {
                                    this.showVisible(
                                      item.review.starRating,
                                      item.review
                                    );
                                  }}
                                >
                                  <img src={bulbImg} />
                                </button>

                              </div>
                              </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <h3 id="nodata">No Data Found</h3>
              )}
            </ul> */}
            {this.state.getlocationData.length > 0
              ? this.state.getlocationData.map((item, i) => (
                  <div
                    className="approve_popupmodal"
                    id={"approve_popupmodal" + i}
                    hidden
                  >
                    {/* {console.log("ppppp",id)} */}
                    <div className="approve_popupinner">
                      <div className="approve_pophead">
                        <span>Approve & Post</span>
                        <Icon.XSquare
                          className="new_chatclose text-white"
                          onClick={() => {
                            this.CloseAprovePopup(i);
                          }}
                        />
                      </div>
                      <div className="approve_popbody">
                        Do you want to approve the review response ?
                      </div>
                      <div className="approve_popfooter">
                        <button
                          className="btn-search"
                          onClick={() =>
                            this.replaySave(
                              i,
                              item.reviewId,
                              item.name,
                              item.reviewReplyDefault
                            )
                          }
                        >
                          Approve
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary"
                          onClick={() => {
                            this.CloseAprovePopup(i);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              : ""}
            {/* <!------------------------------Review------------------------------------> */}
            {/* <div className="col-md-4 col-lg-2 col-sm-3">
                  <select className="form-control" id="Location"
                  onChange={(e)=>this.handleLocation(e)}
                 >
                    <option value="0" >Select Location</option>
                    {this.state.allLocationdata.map((item)=>(
                
                    <option value={item.name}  >{item.title}</option>
                    ))}
                  </select>
                </div> */}
            <>
              {/* <div className="row d-flex align-items-center mx-0 filterSectionDate">
                <div className="col-md-4 col-lg-2 col-sm-3">
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={(date) => this.handleChangeStart(date)}
                    name="startDate"
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    placeholderText="From Date"
                  />
                </div>
                <div className="col-md-4 col-lg-2 col-sm-3">
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={(date) => this.handleChangeEnd(date)}
                    name="startDate"
                    id="endDate"
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    placeholderText="To Date"
                  />
                </div>
                <div className="col-md-4 col-lg-2 col-sm-3">
                  <ReactStars
                    count={5}
                    onChange={(newRating) => this.ratingChanged(newRating)}
                    size={24}
                    activeColor="#ffd700"
                  />
                </div>
                <div className="col-md-4 col-lg-2 col-sm-3">
                  <select className="form-control" id="sentiment">
                    <option value="0">Select</option>
                    <option value="POSITIVE">POSITIVE</option>
                    <option value="NEUTRAL">NEUTRAL</option>
                    <option value="NEGATIVE">NEGATIVE</option>
                  </select>
                </div>
                <div className="col-md-4 col-lg-2 col-sm-3">
                  <button
                    className="btn btn-primary fetch_btn"
                    onClick={() => this.getfilterData()}
                  >
                    Fetch data
                  </button>
                </div>
              </div> */}
              {this.state.getCompanyData.length !== 0 ? (
                <div className="company_review">
                  {/* <div className="col-md-12">
                    <label className="col-form-label">
                      <strong>
                        {this.state.getCompanyData[0].location.title}
                      </strong>
                      <hr></hr>
                    </label>
                    <label className="col-form-label">
                      {this.state.getCompanyData[0].location.storefrontAddress.addressLines.join(
                        " "
                      )}{" "}
                      {
                        this.state.getCompanyData[0].location.storefrontAddress
                          .locality
                      }{" "}
                      {
                        this.state.getCompanyData[0].location.storefrontAddress
                          .AdministrativeArea
                      }{" "}
                      {
                        this.state.getCompanyData[0].location.storefrontAddress
                          .postalCode
                      }
                    </label>
                  </div> */}
                </div>
              ) : (
                // <div className="company_review">
                //   <p>
                //     {" "}
                //     <b className="mr-2">Title:</b>
                //     {this.state.getCompanyData[0].location.title}
                //   </p>
                //   <p>
                //     <b className="mr-2">Address:</b>
                //     {this.state.getCompanyData[0].location.storefrontAddress.addressLines.join(
                //       " "
                //     )}{" "}
                //     {
                //       this.state.getCompanyData[0].location.storefrontAddress
                //         .locality
                //     }{" "}
                //     {
                //       this.state.getCompanyData[0].location.storefrontAddress
                //         .AdministrativeArea
                //     }{" "}
                //     {
                //       this.state.getCompanyData[0].location.storefrontAddress
                //         .postalCode
                //     }
                //   </p>
                // </div>
                ""
              )}
            </>
          </div>
          {/* <div className="review_indexreport">
            {this.state.getlocationData.length > 0 ? (
              this.state.getlocationData.map((item, i) => (
                <>
                  <div className="table-responsive review_tableList">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{width:"300px"}}>Address</th>
                          <th style={{width:"200px"}} scope="col"> Name</th>
                          <th style={{width:"150px"}} >Review</th>
                          <th style={{width:"100px"}} scope="col">Date</th>
                          <th>Sentiment</th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{width:"300px"}}>
                            <p>
                              {addressarray
                                .filter((data) => {
                                  return (
                                    data.name.split("/")[1] ===
                                    item.name.split("/")[3]
                                  );
                                })
                                .map((obj) => obj.title)}
                            </p>
                            <p>
                              {addressarray
                                .filter((data) => {
                                  return (
                                    data.name.split("/")[1] ===
                                    item.name.split("/")[3]
                                  );
                                })
                                .map(
                                  (obj) => obj.storefrontAddress.addressLines
                                )}
                            </p>
                          </td>
                          <td  style={{width:"200px"}} >
                            <div className="d-flex align-items-center">
                              <div className="user_review_name">
                                <span>
                                  {item.review.reviewer.displayName.charAt(0)}
                                </span>
                              </div>
                              {item.review.reviewer.displayName}
                            </div>
                          </td>
                          <td style={{width:"150px"}}>
                            <ReactStars
                              key={item.review.starRating}
                              count={5}
                              size={24}
                              edit={false}
                              activeColor="#ffd700"
                              value={
                                item.review.starRating === "ONE"
                                  ? 1
                                  : item.review.starRating === "TWO"
                                    ? 2
                                    : item.review.starRating === "THREE"
                                      ? 3
                                      : item.review.starRating === "FOUR"
                                        ? 4
                                        : item.review.starRating === "FIVE"
                                          ? 5
                                          : 0
                              }
                            />
                          </td>
                          <td style={{width:"100px"}}>
                            {moment(item.review.createTime).format(
                              "DD/MM/YYYY"
                            )}
                          </td>
                          <td>
                            
                            {this.state.sentimentalList.length > 0
                              ? this.state.sentimentalList
                                .filter(
                                  (obj) =>
                                    obj.review_id == item.review.reviewId
                                    
                                )
                                .map((data) => (
                                  <span
                                    key={data.status}
                                    className="review-right"
                                  >
                                    {console.log("data rew",data.status)}
                                    <span
                                      className={
                                        data.status === "POSITIVE"
                                          ? "review_mixedvalue review_green"
                                          : data.status == "NEUTRAL"
                                            ? "review_mixedvalue review_yellow"
                                            : data.status == "NEGATIVE"
                                              ? "review_mixedvalue review_red"
                                              : ""
                                      }
                                    >
                                      {data.status}
                                    </span>
                                    {(item.review.starRating === "FIVE" ||
                                      item.review.starRating === "TWO" ||
                                      item.review.starRating === "ONE") &&
                                      item.review.comment != "" &&
                                      data.status === "" ? (
                                      <ScaleLoader
                                        color="#36d7b7"
                                        height={20}
                                        width={2}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                ))
                              : ""}
                          </td>
                          
                        </tr>
                        <tr>
                          <td colSpan="5" className="text-center m-auto">
                            <div className="review_box text-left">
                            
                              <div className="review_content">
                                {item.review.comment}
                              </div>
                            </div>
                            <div className="review_box text-left">
                       
                              <div>
                                <div className="review_reply">
                                  <textarea
                                    className="form-control"
                                    placeholder="Reply publicly"
                                    value={
                                      this.state.radioComment ||
                                        item.review.reviewReply === undefined
                                        ? ""
                                        : item.review.reviewReply.comment
                                    }
                                    rows="3"
                                    id={"replymessage" + item.review.reviewId}
                                    onChange={(e) =>
                                      this.setState({
                                        replyComments: e.target.value,
                                      })
                                    }
                                  ></textarea>
                                </div>
                                <div className="review_btns">
                                  <button
                                    className="review_buttion"
                                    onClick={() =>
                                      this.templateShow(
                                        item.review.reviewId,
                                        item.review.starRating
                                      )
                                    }
                                  >
                                    Template
                                  </button>
                                  <div className="review_btn_reply">
                                  {item.review.reviewReply === undefined ? (
                                    ""
                                  ) : item.review.reviewReply.comment ? (
                                    
                                    <Icon.Trash className="text-danger" 
                                    id={"deleteid" + item.review.reviewId}
                                    onClick={() =>
                                      this.replayDelete(
                                        item.review.reviewId,
                                        item.review.comment,
                                        item.name
                                      )
                                    }></Icon.Trash> 
                                  ) : (
                                    ""
                                  )}
                            
                                    <Icon.Send className="text-primary" 
                                    onClick={() =>
                                      this.replaySave(
                                        item.review.reviewId,
                                        item.name
                                      )
                                    }></Icon.Send> 
                                </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              ))
            ) : (
              <h3>No Data Found</h3>
            )}
          </div> */}
        </div>
        {/* <div className="reply_popup">
          <div className="reply_popup_inner">
            <div className="reply_popup_head d-flex align-items-center">
              <span className="popup_reply_head">
                <Icon.ArrowLeft />
                Reply to review
              </span>
              <span
                className="ml-auto close_popup_review"
                onClick={() => {
                  this.CloseReviewPopup();
                }}
              >
                <Icon.X />
              </span>
            </div>
            <div className="reply_popup_body">
              <div className="reply__bodyouter">
                <div className="reply_body_main">
                  <div className="reply_popup_left">
                    <div className="user_review_name">
                      <span>
                        <img
                          src={this.state.profilePhotoUrl}
                          alt=""
                          srcset=""
                        />
                      </span>
                    </div>
                  </div>
                  <div className="reply_popup_right">
                    <div className="user_right_review">
                      <div className="review_profile">
                        <a href="#">
                          {this.state.displayName}
                        </a>
                      </div>
                      <div className="review_ratedate">
                        <ReactStars
                          key={this.state.starRating}
                          count={this.state.starRating}
                          value={this.state.starRating}
                          size={20}
                          activeColor="#ffd700"
                        />
                        <span className="review_datespan">
                          {this.state.createTime}
                        </span>
                      </div>
                      <p>{this.state.comment}</p>
                    </div>
                  </div>
                </div>
                <div className="reply_body_main">
                  <div className="reply_popup_left">
                    <div className="user_review_name">
                      <span>X</span>
                    </div>
                  </div>
                  <div className="reply_popup_right">
                    <div className="user_right_review">
                      <div className="review_profile">
                        <div className="review_owner_name">
                          <span className="d-block user_blcok_review">
                            Xilligence
                          </span>
                          <span className="d-block">(owner)</span>
                        </div>
                        <div className="review_owner_drop">
                          <ul className="nav">
                            <li>
                              {" "}
                              <label>Template</label>
                            </li>
                            <li className="d-flex align-items-center template_divli">
                              <input
                                type="Button"
                                value="Template"
                                placeholder="Choose Template"
                                onClick={() => this.templateShow()}
                              />
                            </li>
                            <li>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => this.replayDelete()}
                              >
                                Delete
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="reply_input">
                        <span>0 / 4000</span>
                      </div>
                      <p className="footer_text_review">
                        This customer will be notified about your reply, and it
                        will be publicity visible on your Bussiness Profile
                      </p>
                    </div>
                  </div>
                </div>
                <div className="review_footer">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => this.replaySave()}
                  >
                    Post
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div id={"templateView" + this.state.revId} className="template_popup">
          <div className="review_owner_dropdown" id="templateList">
            <div className="review_owner_top">
              {/* <div className="template_head">
                <button
                  className="btn bt-transparent p-0 border-0"
                  onClick={() => this.templateViewClose()}
                >
                  <Icon.X />
                </button>
              </div> */}
              <div class="reply_popup_head d-flex align-items-center popup_review_common">
                <span class="popup_reply_head">
                  <Icon.FileText
                    onClick={() => {
                      $("#templateView" + this.state.revId).show();
                      $("#templateListAll" + this.state.revId).show();
                      $("#newTemplate").hide();
                    }}
                  />
                  Template
                </span>
                <span class="ml-auto close_popup_review">
                  <Icon.X onClick={() => this.templateViewClose()} />
                </span>
              </div>
              <ul
                className="nav flex-column"
                id={"templateListAll" + this.state.revId}
              >
                <div className="template_head review_temphead">
                  <button
                    className="btn bg-transparent p-0 border-0"
                    onClick={() => this.newTemplate()}
                  >
                    Create Template
                  </button>
                </div>
                {this.state.reviewList
                  .filter((obj) => obj.star_rate === this.state.starRating)
                  .map((item) => (
                    <li>
                      <p>
                        {" "}
                        {item.template}
                        <div
                          className="review_edit_popup"
                          onClick={() =>
                            this.editReview(
                              item.template,
                              item.id,
                              item.star_rate
                            )
                          }
                        >
                          Do you want to edit?
                        </div>
                      </p>
                      <span>
                        {/* <Icon.Edit
                        className="text-success"
                        onClick={() =>
                          this.editReview(
                            item.template,
                            item.id,
                            item.star_rate
                          )
                        }
                      /> */}
                        {/* <Icon.Trash
                        className="text-danger"
                        onClick={() => this.deleteReview(item.id)}
                      /> */}
                        <span
                          className="text-danger"
                          onClick={() => this.deleteReview(item.id)}
                        >
                          Delete
                        </span>
                        <input
                          type="radio"
                          name="templateradio"
                          id="templateradio"
                          onClick={() => this.selectTemplate(item.template)}
                        />
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="review_Popupdiv" id="newTemplate">
              <div className="review_popup_div text-right"></div>
              <form>
                <div className="popup_formgroup">
                  <label>Rate</label>
                  <select
                    className="form-control"
                    id="ratedropdown"
                    onChange={(e) =>
                      this.setState({
                        rateChange: e.target.value,
                      })
                    }
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <div className="popup_formgroup">
                  <label>Message</label>
                  <textarea
                    className="form-control"
                    value={this.state.messageChange}
                    onChange={(e) =>
                      this.setState({
                        messageChange: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="popup_formgroup text-right mt-1">
                  <div
                    className="btn btn-secondary mr-2"
                    type="button"
                    onClick={() => this.backReview()}
                  >
                    Back
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.saveTemplate()}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div id="worldcloud" className="template_popup">
          <div className="review_owner_dropdown" id="templateList">
            <div className="review_owner_top">
              <div class="reply_popup_head d-flex align-items-center popup_review_common">
                <span class="popup_reply_head">
                  <Icon.FileText
                    onClick={() => {
                      $("#templateView" + this.state.revId).show();
                      $("#templateListAll" + this.state.revId).show();
                      $("#newTemplate").hide();
                    }}
                  />
                  Word Cloud
                </span>
                <span class="ml-auto close_popup_review">
                  <Icon.X onClick={() => this.worldCloudClose()} />
                </span>
              </div>
              <WordCloud />
            </div>
          </div>
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}

export default ApproveReview;
